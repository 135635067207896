import Tree, {CustomNodeElementProps, Point, TreeNodeDatum} from 'react-d3-tree';
import {HierarchyPointNode} from 'd3-hierarchy';
import {useGetCategoryHierarchyQuery} from '../../api/reduxAPI';
import React, {useEffect, useState} from 'react';
import {CategoryHierarchy} from '../../interfaces/categoryHierarchy';
import {CategoryDetailSideDrawer} from './compontents/CategoryDetailSideDrawer';
import {endPointGetImage} from '../../constants';
import uuid from 'react-uuid';


export const CategoryTree = () => {

	const [selectedCategoryNode, setSelectedCategoryNode] = useState<CategoryHierarchy | undefined>();
	const [translatePoint, setTranslatePoint] = useState<Point>();
	const {data:categoryHierarchy} = useGetCategoryHierarchyQuery({x:undefined});

	const handleNodeClick = (node: HierarchyPointNode<TreeNodeDatum>) => {
		const x = node.data as unknown as CategoryHierarchy;
		setSelectedCategoryNode(x);
	};

	useEffect(() => {
		const elem = document.getElementById('categoryTreeDiv');
		if (elem !== null){
			const rect = elem.getBoundingClientRect();
			setTranslatePoint({ x: rect.width / 2, y: rect.height / 20 });
		}
	}, []);


	interface renderRectSvgNodeParams extends CustomNodeElementProps {
		handleNodeClick: (node: HierarchyPointNode<TreeNodeDatum>) => void
	}

	const renderRectSvgNode = (params:renderRectSvgNodeParams) => {
		let imageID = '';

		if (('imageId' in params.hierarchyPointNode.data) && typeof params.hierarchyPointNode.data.imageId === 'string' ) {
			imageID = params.hierarchyPointNode.data.imageId;
		}
		return (
			<g onClick={()=> params.handleNodeClick(params.hierarchyPointNode)} >
				<circle r={20} fill={'white'} />
				<image
					href={endPointGetImage(imageID)}
					x={-15}
					y={-15}
					className="object-scale-down w-8 h-8"
					id={'img' + uuid()}
				/>
				<text strokeWidth="1" x="25">
					{params.nodeDatum.name}
				</text>
			</g>
		);
	};


	return (
		<div id="categoryTreeDiv" className={'flex w-full h-full'}>
			{categoryHierarchy && translatePoint &&
				<Tree
					data={categoryHierarchy}
					orientation="vertical"
					pathFunc="step"
					collapsible={false}
					translate={translatePoint}
					zoomable={true}
					renderCustomNodeElement={(rd3tNodeProps)=>renderRectSvgNode({...rd3tNodeProps, handleNodeClick})}
				/>
			}
			<CategoryDetailSideDrawer
				isVisible={selectedCategoryNode !== undefined}
				onClose={() => setSelectedCategoryNode(undefined)}
				hierarchy={selectedCategoryNode}
			/>
		</div>
	);
};