import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import {authSlice} from '../slices';
import storage from 'redux-persist/lib/storage';
import {webApi} from '../api/reduxAPI';
import {notificationSlice} from '../slices/notification'; // defaults to localStorage for web

export function loadState(key: string) {
	try {
		const serializedState = localStorage.getItem(key) ?? sessionStorage.getItem(key);
		if (!serializedState) return undefined;
		return JSON.parse(serializedState);
	} catch (e) {
		return undefined;
	}
}


const persistConfig = { storage:  storage};

const authReducer = persistReducer(
	{ ...persistConfig, key: authSlice.name },
	authSlice.reducer,
);


const combinedReducer = combineReducers({
	[webApi.reducerPath]: webApi.reducer,
	[authSlice.name]: authReducer,
	[notificationSlice.name]: notificationSlice.reducer,
});


export const store = configureStore({
	reducer: combinedReducer,

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(webApi.middleware),

	preloadedState: {
		[authSlice.name]: loadState(authSlice.name),
		[notificationSlice.name]: loadState(notificationSlice.name),
	}
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;