import {CompanyPartnership, CompanyPartnershipStatus} from '../../interfaces/companyPartnership';
import React, {useEffect, useState} from 'react';
import {Constants} from '../../constants';
import DataTable, {SortOrder, TableColumn} from 'react-data-table-component';
import IconButton, {IconType} from '../../components/button/IconButton';
import Button, {ButtonType} from '../../components/button/Button';
import SearchBar from '../../components/searchBar/SearchBar';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import {toast} from 'sonner';
import {
	useAcceptCompanyPartnershipMutation, useDeleteCompanyPartnershipMutation,
	useListCompanyPartnershipsAsSellerQuery,
} from '../../api/reduxAPI';
import {usePagination} from '../../providers/usePagination';

export default function IncomingPartnershipRequests() {

	const pagination = usePagination();
	const errorHandling = useErrorHandling();

	const [sortDirection, setSortDirection] = useState<string>(Constants.SortDirection.ASC);
	const [sortField, setSortField] = useState<string>('buyer.name');
	const [searchValue, setSearchValue] = useState<string>('');

	const [acceptPartnership] = useAcceptCompanyPartnershipMutation();
	const [deletePartnership] = useDeleteCompanyPartnershipMutation();

	const {data:{companyPartnerships = [], nextPageToken = ''} = {} }  = useListCompanyPartnershipsAsSellerQuery({
		status: CompanyPartnershipStatus.requested,
		searchBuyerName:searchValue,
		pageSize:20,
		token: pagination.nextPageToken,
		sortPrimary:sortField,
		sortDirectionPrimary:sortDirection === 'desc' ? 'DESC' : 'ASC'
	});

	useEffect(() => {
		pagination.setCurrentToken(nextPageToken);
	}, [nextPageToken]);

	const onSortChange = (field:string, direction:string) => {
		setSortField(field);
		setSortDirection(direction);
	};


	const onAcceptPartnership = async (partnershipId: string, buyerName: string) => {
		try {
			await acceptPartnership({companyPartnershipID:partnershipId}).unwrap();
			const message = 'Anfrage zur Partnerschaft mit dem Unternehmen ' + buyerName + ' akzeptiert.';
			toast.success(message);
		} catch (e:any){
			errorHandling(e);
		}
	};

	const onDeletePartnership = async (partnershipId: string, name:string) => {
		try {
			await deletePartnership({companyPartnershipID:partnershipId}).unwrap();
			const message = 'Anfrage zur Partnerschaft von Unternehmen ' + name + ' geloescht.';
			toast.success(message);
		} catch (e:any){
			errorHandling(e);
		}
	};


	const colums: TableColumn<CompanyPartnership>[] = [
		{
			name: 'Name',
			selector: (row) => row.buyer?.name ?? '',
			center: true,
			sortable: true,
			width: '250px',
		},
		{
			name: 'Aktion',
			right: true,
			grow: 1,
			cell: (row, rowIndex, column) => (
				<div className="flex items-center gap-2 pr-1">
					<IconButton
						onClick={() => onAcceptPartnership(row.id, row.buyer?.name ?? '').then()}
						icon={IconType.CHECK}
						title="Partnerschaft akzeptieren"/>
					<IconButton
						onClick={() => onDeletePartnership(row.id, row.buyer?.name ?? '').then()}
						icon={IconType.TRASH}
						title="Partnerschaft loeschen"/>
				</div>
			),
		},
	];

	return (
		<div>
			<SearchBar
				wrapperClasses={'w-1/3 pt-12 px-12'}
				results={companyPartnerships}
				searchStr={searchValue}
				setSearchStr={setSearchValue}
				onClickResult={(partnershipId: string, name: string) => onAcceptPartnership(partnershipId, name)}/>

			<div className={'w-1/3 px-12 flex flex-col'}>
				<DataTable
					columns={colums}
					data={companyPartnerships}
					defaultSortFieldId={1}
					defaultSortAsc={true}
					sortServer
					onSort={(column, sortDirection)=>onSortChange(column.sortField??'', sortDirection)}
				/>

				{!(nextPageToken === '@end' || nextPageToken === '') &&
					<Button
						wrapperClasses="py-5 self-center"
						label="Mehr laden"
						onClick={() => pagination.loadMore()}
					/>
				}
			</div>
		</div>
	);
}
