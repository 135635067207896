import {User} from '../../interfaces';
import {LoginParams, UserRegisterParams} from '../../api/interfaces';
import {useErrorHandling} from '../error/ErrorProvider';
import {useAppSelector} from '../useAppSelector';
import {useLoginMutation, useRegisterCompanyUserMutation} from '../../api/reduxAPI';
import {useStorage} from '../useStorage/useStorage';
import {useAppDispatch} from '../useAppDispatch';
import {authSlice, loginUser, logoutUser} from '../../slices';
import {AuthStorageState} from '../../interfaces/storageStateTypes';
import axios from 'axios';
import {useEffect} from 'react';

type UseAuthHook = () => {
	user: User | null;
	loggedIn: boolean;
	token: string;
	login: (params: LoginParams) => Promise<boolean>;
	register: (params: UserRegisterParams) => Promise<boolean>;
	logout: () => void;
	loading: boolean;
};


export const useAuth: UseAuthHook = () => {

	const errorHandling = useErrorHandling();

	const { user, loggedIn, token } = useAppSelector((state) => state.auth);

	const [triggerLogin, {isLoading:isLoginLoading}] = useLoginMutation();
	const [triggerRegistration, {isLoading:isRegisterLoading}] = useRegisterCompanyUserMutation();

	const { saveState, clearState } = useStorage();
	const dispatch = useAppDispatch();

	useEffect(() => {
		// TODO REMOVE THIS when finished refactoring to redux
		axios.defaults.headers['Authorization'] = 'Bearer ' + token;
	}, []);


	const login = async (loginParams: LoginParams): Promise<boolean> => {

		const loginRes = await triggerLogin(loginParams);

		if (!('data' in loginRes) || loginRes.data === undefined || !('token' in loginRes.data) || loginRes.data['token'] === '') {

			// TODO REMOVE THIS when finished refactoring to redux
			delete axios.defaults.headers['Authorization'];

			clearState();
			errorHandling(loginRes.error as Error);
			return false;
		}

		dispatch(loginUser({ user: loginRes.data.user, token: loginRes.data.token }));

		// TODO REMOVE THIS when finished refactoring to redux
		axios.defaults.headers['Authorization'] = 'Bearer ' + loginRes.data.token;

		saveState<AuthStorageState>(loginParams.keepLoggedIn, authSlice.name,
			{
				user: loginRes.data.user,
				userID: loginRes.data.user?.id,
				loggedIn: true,
				token: loginRes.data.token,
				keepLoggedIn: loginParams.keepLoggedIn,
			}
		);
		
		return true;
	};


	const register = async (registerParams: UserRegisterParams): Promise<boolean> => {

		const registerRes = await triggerRegistration(registerParams);

		if (!('data' in registerRes) || registerRes.data === undefined || !('token' in registerRes.data) || registerRes.data['token'] === '') {
			clearState();
			errorHandling(registerRes.error as Error);

			// TODO REMOVE THIS when finished refactoring to redux
			delete axios.defaults.headers['Authorization'];
			return false;
		}

		dispatch(loginUser({ user: registerRes.data.user, token: registerRes.data.token }));

		saveState<AuthStorageState>(false, authSlice.name, {
			user: registerRes.data.user,
			userID: registerRes.data.user?.id,
			loggedIn: true,
			token: registerRes.data.token,
			keepLoggedIn: false,
		});

		// TODO REMOVE THIS when finished refactoring to redux
		axios.defaults.headers['Authorization'] = 'Bearer ' + registerRes.data.token;

		return true;
	};


	const logout = async () => {
		clearState();
		dispatch(logoutUser());
	};

	return {
		login,
		token,
		user,
		loggedIn,
		logout,
		loading: isLoginLoading || isRegisterLoading,
		register
	};
};
