import {PropertyValues} from './property';

export interface Filter {
    id: string;
    name: string;
    unit: string | undefined;
    filterType: FilterType;
    filterValues: FilterValues;
    selectedFilterValues: SelectedFilterValues | undefined
}

export type SelectedFilterValues = {
    propertyValues: string[]
    rangeMin: number | undefined
    rangeMax: number | undefined
}

export type FilterValues = {
    propertyValues: PropertyValues[];
    rangeMin: number | undefined;
    rangeMax: number | undefined;
    rangeValues: number[];
    relevance: number
};

export type AppliedFilter = {
    filterId: string
    minRange: number | undefined
    maxRange: number | undefined
    propertyValues: string[] | undefined
}

export enum FilterType {
    PropertyFilter='PROPERTY',
    RangeFilter= 'RANGE'
}
