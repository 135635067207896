import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useAuth} from '../auth/useAuth';
import {useInterval} from 'usehooks-ts';
import {WebsocketTopicIDs} from '../../interfaces';
import {ReplaceUrlToWebsocketPrefix} from './Utils';


export interface WebsocketMessage {
	topicID:WebsocketTopicIDs
	subscriptionID:string
	data:string
}


export interface WebsocketConnectionContextType {
    websocket:WebSocket | undefined;
}

const WebsocketConnectionContext = createContext<WebsocketConnectionContextType>({
	websocket: undefined
});

export const useWebsocketConnection = () => {
	return useContext(WebsocketConnectionContext);
};


export const WecksocketConnectionProvider: React.FC<PropsWithChildren<any>> = (props) => {

	const auth = useAuth();
	const [websocket, setWebsocket] = useState<WebSocket|undefined>(undefined);

	// close websocket when unmount
	useEffect(() => {
		return(()=>{
			if (websocket){
				websocket.close();
			}
		});
	}, []);

	// auto reconnect when websocket is closed
	useInterval(() => {
		if(websocket?.readyState === websocket?.CLOSED && auth.token && auth.token !== ''){
			connect(auth.token).
				catch(function (err:any){
					console.log(err);
				});
		}
	}, auth.token && auth.token !== '' ? 5000 : null);


	// connect when logging in
	useEffect(() => {
		if(auth.token && auth.token !== '' && websocket?.readyState === websocket?.CLOSED){
			connect(auth.token).
				catch(function (err:any){
					console.log(err);
				});
		}
	}, [JSON.stringify(auth.token)]);


	async function connect(jwtToken:string){
		return new Promise(function (resolve, reject) {

			const url = ReplaceUrlToWebsocketPrefix(process.env.REACT_APP_API_URL??'') + '/ws';

			const socket = new WebSocket(url,
				[
					'base64url.bearer.authorization.k8s.io.' + jwtToken,
					'base64.binary.k8s.io'
				]
			);

			socket.onopen = function (){
				console.log('WecksocketConnectionProvider: websocket is open', socket);
				setWebsocket(socket);
				resolve(socket);
			};

			socket.onerror = function (err) {
				reject(err);
			};
		});
	}

	return (
		<WebsocketConnectionContext.Provider value={{websocket}}>
			{props.children}
		</WebsocketConnectionContext.Provider>
	);
};