import React, {useEffect} from 'react';
import {Constants} from '../../../constants';
import ComboBox from '../../../components/comboBox/ComboBox';
import classNames from 'classnames';
import CheckBox from '../../../components/checkBox/CheckBox';
import {PermissionGroup} from '../../../interfaces';
import {useListPermissionGroupsQuery} from '../../../api/reduxAPI';
import {useTranslation} from 'react-i18next';


interface Props {
	selectedPermissionGroup: PermissionGroup | undefined
	setSelectedPermissionGroup: (permissionGroup: PermissionGroup|undefined) => void
	webUsePermission: boolean
	setWebUsePermission: React.Dispatch<React.SetStateAction<boolean>>
	appUsePermission: boolean
	setAppUsePermission: React.Dispatch<React.SetStateAction<boolean>>
	wrapperClasses?: string
}

function PermissionsSelection(
	{
		appUsePermission,
		setAppUsePermission,
		webUsePermission,
		setWebUsePermission,
		selectedPermissionGroup,
		setSelectedPermissionGroup,
		wrapperClasses
	} : Props){

	const {t} = useTranslation();

	const {data:{permissionGroups = []} = {} }  = useListPermissionGroupsQuery({
		sortPrimary:'name',
		sortDirectionPrimary:'ASC'
	});


	useEffect(() => {
		if (selectedPermissionGroup){

			let res = selectedPermissionGroup.permissions.find((item) => item === Constants.Permissions.AppUse);
			if(res){
				setAppUsePermission(true);
			} else {
				setAppUsePermission(false);
			}

			res = selectedPermissionGroup.permissions.find((item) => item === Constants.Permissions.WebUse);
			if(res){
				setWebUsePermission(true);
			} else {
				setWebUsePermission(false);
			}
		}
	}, [selectedPermissionGroup]);

	function onSelectPermissionGroup(selected: PermissionGroup|undefined) {
		if (selected === null || selected === undefined) {
			setSelectedPermissionGroup(undefined);
		} else {
			setSelectedPermissionGroup(selected);
		}
	}

	return(
		<div className={classNames(wrapperClasses)}>
			<ComboBox
				wrapperClasses="py-2"
				title="Berechtingungsgruppe"
				selected={{
					key:selectedPermissionGroup ? selectedPermissionGroup.name :'',
					label:selectedPermissionGroup ? t('permissionGroups.' + selectedPermissionGroup.name ) : '',
					object:selectedPermissionGroup
				}}
				items={permissionGroups.map(item => ({key: item.name, label: t('permissionGroups.' + item.name ), object: item}))}
				onChangeSelected={(item) => onSelectPermissionGroup(item)}
			/>

			<div className="flex gap-5">
				<div className="text-gray-700 text-sm">Darf verwenden: </div>
				<div className="pb-1 flex gap-5">
					<CheckBox
						label="Verwaltung"
						checked={webUsePermission}
						disabled={true}/>
					<CheckBox
						label="Bestell-App"
						checked={appUsePermission}
						disabled={true}/>
				</div>
			</div>


		</div>
	);
}

export default PermissionsSelection;
