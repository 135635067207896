import Button, {ButtonType} from '../button/Button';
import TextArea from '../inputField/TextArea';
import React, {useEffect, useState} from 'react';
import { PiPaperclipHorizontalLight } from 'react-icons/pi';
import {useAuth} from '../../providers/auth/useAuth';
import {useWebsocketConnection, WebsocketMessage} from '../../providers/websocket/WebsocketProvider';
import {ChatInterface, WebsocketTopicIDs} from '../../interfaces';
import {API} from '../../api';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import {useTranslation} from 'react-i18next';
import {ChatFileUploadPreviews} from './ChatFileUploadPreviews';
import {ChatMessageWebsocketData} from './chatMessageWebsocketData';

interface Props {
    chat: ChatInterface
}

export default function ChatMessageInput({chat}: Props) {

	const auth = useAuth();
	const websocketConn = useWebsocketConnection();
	const errorHandling = useErrorHandling();
	const {t} = useTranslation();

	const fileRef = React.useRef<HTMLInputElement>(null);
	const [fileList, setFileList] = useState<FileList | undefined>(undefined);
	const [message, setMessage] = useState<string>('');
	const [isSendButtonDisabled, setIsSendButtonDisabled] = useState<boolean>(true);

	const onSendMessage = async () => {

		if (fileList && fileList.length > 0) {

			const formData = new FormData();

			formData.append('text', message);

			Array.from(fileList).forEach((file) => formData.append('files', file));

			try {
				await API.Chats.uploadImages(chat.id, formData);
				setFileList(undefined);
			} catch (e:any){
				setFileList(undefined);
				errorHandling(e);
			}

		} else {

			const messageObj:ChatMessageWebsocketData = {
				userID:auth.user?.id ?? '',
				text:message,
			};

			const wsMessage:WebsocketMessage = {
				topicID:WebsocketTopicIDs.chat,
				subscriptionID: chat.id??'',
				data:JSON.stringify(messageObj)
			};
			websocketConn.websocket?.send(JSON.stringify(wsMessage));
		}

		setMessage('');
	};

	useEffect(() => {
		if(fileList && fileList.length > 0 || message !== ''){
			setIsSendButtonDisabled(false);
		} else {
			setIsSendButtonDisabled(true);
		}
	}, [JSON.stringify(fileList), message]);

	return(
		<div>
			{fileList && fileList.length > 0 &&
				<div className={'flex'}>
					<ChatFileUploadPreviews
						fileList={fileList}
						onDeleteFiles={()=>setFileList(undefined)}
					/>
				</div>
			}
			<div className={'w-full flex gap-3'}>
				<TextArea
					id={'message'}
					wrapperclasses={''}
					title={''}
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					rows={3}
				/>
				<div className={'pt-4'}>
					<input
						id={'message_input_image_files_input'}
						type={'file'}
						multiple={true}
						onChange={(e) => setFileList(e.target.files ?? undefined)}
						style={{display: 'none'}}
						ref={fileRef}
					/>
					<PiPaperclipHorizontalLight
						className="h-8 w-8 text-gray-400 hover:text-gray-700 cursor-pointer"
						aria-hidden="true"
						type={'button'}
						onClick={() => fileRef.current?.click()}
					/>
				</div>
				<Button
					wrapperClasses={'pt-4'}
					label={t('chat.messageInput.send')}
					disabled={isSendButtonDisabled}
					onClick={() => onSendMessage()}
				/>
			</div>
		</div>
	);
}