
export const useStorage = () => {
	//
	const saveState = <T>(useLocalStorage: boolean, key: string, state: T) => {
		const serializedState = JSON.stringify(state);

		!useLocalStorage && localStorage.clear();

		const storage = useLocalStorage ? localStorage : sessionStorage;

		storage.setItem(key, serializedState);
	};

	const loadState = <T>(key: string): T | undefined => {
		const serializedState = localStorage.getItem(key) ?? sessionStorage.getItem(key);

		if (serializedState === null) {
			return undefined;
		}

		return JSON.parse(serializedState);
	};

	const clearState = () => {
		localStorage.clear();
		sessionStorage.clear();
	};

	return { saveState, loadState, clearState };
};
