import {useParams} from 'react-router-dom';
import React, {useState} from 'react';
import {SupportSessionType} from '../../../interfaces/supportSession';
import Chat from '../../../components/chat/Chat';
import Button from '../../../components/button/Button';
import {useTranslation} from 'react-i18next';
import {FormatDateWithTime} from '../../../utils';
import {useAuth} from '../../../providers/auth/useAuth';
import {useGetSupportSessionQuery} from '../../../api/reduxAPI';
import {OrderComplaintView} from './components/OrderComplaintView';
import {ProductDetailsWithVariantsTable} from './components/ProductDetailsWithVariantsTable';
import {ModalV2} from '../../../components/modal/ModalV2';
import {VariantDetails} from './components/VariantDetails';


export default function SupportSessionAsRequester(){

	const {id:supportSessionID} = useParams<string>();
	const {t} = useTranslation();
	const auth = useAuth();

	const [isVariantDetailModalVisible, setIsVariantDetailModalVisible] = useState<boolean>(false);
	const [isProductDetailModalVisible, setIsProductDetailModalVisible] = useState<boolean>(false);
	const [isOrderComplaintModalVisible, setIsOrderComplaintModalVisible] = useState<boolean>(false);

	const {data:supportSession} = useGetSupportSessionQuery(supportSessionID ? {supportSessionID} : {supportSessionID:''});
	
	return (
		<div className={'h-full flex flex-col'}>
			<div className={'w-full h-screen flex'}>
				{supportSession !== undefined &&
					<>
						{supportSession.requesterID === auth.user?.id &&
							<div className={'w-1/2 pr-10'}>
								<Chat
									chatID={supportSession?.chatID}
									showInput={supportSession.closed === undefined || supportSession.closed === null}
								/>
							</div>
						}

						<div className={'w-1/2 flex flex-col gap-8'}>
							<div>
								<div className={'text-2xl'}>{t('supportSessions.sessionView.concernSummary')}</div>
								<div>
									{supportSession.concernSummary}
								</div>

								{supportSession && supportSession.supportSessionType === SupportSessionType.VariantQuestion && supportSession.variantQuestion &&
									<>
										<Button
											wrapperClasses={'pt-2'}
											label={t('supportSessions.sessionView.showVariantDetails')}
											onClick={()=>setIsVariantDetailModalVisible(true)}
										/>


										<ModalV2
											isOpen={isVariantDetailModalVisible}
											handleClose={() => setIsVariantDetailModalVisible(false)}
										>
											<VariantDetails
												productID={supportSession.variantQuestion.productID}
												variantID={supportSession.variantQuestion.variantID}
											/>
										</ModalV2>
									</>
								}
								{supportSession && supportSession.supportSessionType === SupportSessionType.ProductQuestion && supportSession.productQuestion &&
									<>
										<Button
											wrapperClasses={'pt-2'}
											label={t('supportSessions.sessionView.showProductDetails')}
											onClick={()=>setIsProductDetailModalVisible(true)}
										/>
										<ModalV2
											isOpen={isProductDetailModalVisible}
											handleClose={() => setIsProductDetailModalVisible(false)}
										>
											<ProductDetailsWithVariantsTable
												productID={supportSession.productQuestion.productID}
											/>
										</ModalV2>
									</>
								}
								{supportSession && supportSession.supportSessionType === SupportSessionType.OrderComplaint && supportSession.orderComplaint &&
									<div>
										<Button
											wrapperClasses={'pt-2'}
											label={t('supportSessions.sessionView.showOrderComplaintDetails')}
											onClick={()=>setIsOrderComplaintModalVisible(true)}
										/>
										<ModalV2
											isOpen={isOrderComplaintModalVisible}
											handleClose={() => setIsOrderComplaintModalVisible(false)}
										>
											<OrderComplaintView
												orderComplaint={supportSession.orderComplaint}
											/>
										</ModalV2>
									</div>
								}
							</div>

							{supportSession.requester &&
								<div className={'flex flex-col'}>
									<div className={'text-2xl'}>{t('supportSessions.sessionView.requester')}</div>
									<div>
										{t('details.name')} : {supportSession.requester.firstName} {supportSession.requester.lastName}
									</div>
									<div className={'flex'}>
										<div>{t('details.mail')} :&nbsp;</div>
										<a className={'hover:text-blue-500 hover:underline'}
											href={`mailto:${supportSession.requester.mail}`}
										>
											{supportSession.requester.mail}
										</a>
									</div>
									<div>
										{t('details.company')} : {supportSession.requester.company?.name}
									</div>
								</div>
							}

							<div className={'flex flex-col'}>
								<div className={'text-2xl'}>{t('supportSessions.sessionView.heading')}</div>

								<div>{t('supportSessions.sessionView.created')} : &nbsp; {FormatDateWithTime(supportSession.created)}</div>

								<div className={'flex'}>
									{t('supportSessions.sessionView.closed')} : &nbsp; {supportSession.closed !== null ?
										<div>{FormatDateWithTime(supportSession.closed)}</div> : '--'}
								</div>

								<div>
									{t('supportSessions.sessionTypeName')} : {t(`supportSessions.sessionType.${supportSession.supportSessionType}`)}
								</div>
							</div>

							<div className={'flex flex-col'}>
								<div className={'text-2xl'}>{t('supportSessions.sessionView.supporters')}</div>

								{supportSession.supporters && supportSession.supporters.map((supporter) => {
									return <div key={supporter.id}>{supporter.mail}</div>;
								})
								}

								{supportSession.invitedSupporters && supportSession.invitedSupporters.map(supporter => {
									return <div key={supporter.id} className={'text-gray-400'}>
										{supporter.mail} ({t('supportSessions.sessionView.invitedSupporterIndication')})
									</div>;
								})
								}
							</div>
						</div>
					</>
				}
			</div>
		</div>
	);
}