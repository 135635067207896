import {Outlet} from 'react-router-dom';
import React from 'react';


export default function ManufacturersLayout() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl font-semibold text-gray-900">Registrierte Marken</h1>
			</div>
			<div className={'px-8 pt-8'}>
				<Outlet/>
			</div>
		</div>
	);
}