import React, {FormEvent, useEffect, useState} from 'react';
import axios from 'axios';
import Divider from '../../../components/Divider';
import InputField, {InputFieldType} from '../../../components/inputField/InputField';
import {
	cityRegEx,
	mailRegEx,
	nameRegEx,
	passwordRegEx,
	streetNumberRegEx,
	streetRegEx,
	zipRegEx,
} from '../../../utils/regex';
import {useNavigate} from 'react-router-dom';
import ComboBox from '../../../components/comboBox/ComboBox';
import PasswordInputField from '../../../components/inputField/PasswordInputField';
import CheckBox from '../../../components/checkBox/CheckBox';
import {Country, CountrysISO3166_1, InitialCountryISO3166_1,} from '../../../utils/country';
import {APP_ROUTES, ENDPOINTS} from '../../../constants';
import ImageButton from '../../../components/button/ImageButton';


interface Image {
	data:string
	contentType:string
}

const Registration = () => {
	const navigate = useNavigate();

	const [mail, setMail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [passwordRepeat, setPasswordRepeat] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [companyName, setCompanyName] = useState<string>('');
	const [isBuyer, setIsBuyer] = useState<boolean>(false);
	const [isSeller, setIsSeller] = useState<boolean>(false);
	const [country, setCountry] = useState<Country>(InitialCountryISO3166_1);
	const [addressTitle, setAddressTitle] = useState<string>('');
	const [zip, setZip] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [street, setStreet] = useState<string>('');
	const [streetNumber, setStreetNumber] = useState<string>('');
	const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
	const [inputFieldError, setInputFieldError] = useState<string>('');

	const [isManufacturer, setIsManufacturer] = useState<boolean>(false);
	const [manufacturerName, setManufacturerName] = useState<string>('');
	const [manufacturerImage, setManufacturerImage] = useState<File | null>(null);
	const imageFileRef = React.useRef<HTMLInputElement>(null);
	const [imagePreview, setImagePreview] = useState<Image>({data:'',contentType:''});

	useEffect(() => {
		if (manufacturerImage) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrB64 = (reader.result as string).split(',');
				if (arrB64.length == 2){
					setImagePreview({data:arrB64[1], contentType:manufacturerImage.type});
				}
			};
			reader.readAsDataURL(manufacturerImage);
		}
	}, [manufacturerImage]);

	useEffect(() => {
		if(!isManufacturer){
			setManufacturerName('');
			setManufacturerImage(null);
		}
	}, [isManufacturer]);

	const onRegisterButtonClick = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (mail === '' || !mailRegEx.test(mail)) {
			setInputFieldError('E-Mail-Adresse fehlt');
		} else if (password == '') {
			setInputFieldError('Passwort fehlt');
		} else if (!passwordRegEx.test(password)) {
			setInputFieldError(
				'Ungültiges Passwort: Minimum 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Sonderzeichen, 1 Zahl und eine Mindestlänge von 8 Zeichen.'
			);
		} else if (passwordRepeat == '') {
			setInputFieldError('Bitte wiederholen sie das Passwort');
		} else if (passwordRepeat != password) {
			setInputFieldError('Passwörter stimmen nicht überein');
		} else if (firstName == '') {
			setInputFieldError('Vorname fehlt');
		} else if (lastName == '') {
			setInputFieldError('Nachname fehlt');
		} else if (companyName == '') {
			setInputFieldError('Name des Unternehmens fehlt');
		} else if (!isBuyer && !isSeller) {
			setInputFieldError(
				'Bitte wählen sie aus, ob ihr Unternehmen Produkte verkauft und/oder kauft.'
			);
		} else if (country.code === '') {
			setInputFieldError('Land ungültig');
		} else if (zip == '' || !zipRegEx.test(zip)) {
			setInputFieldError('Postleitzahl ungültig');
		} else if (city === '' || !cityRegEx.test(city)) {
			setInputFieldError('Stadt ungültig');
		} else if (street === '' || !streetRegEx.test(street)) {
			setInputFieldError('Straße ungültig');
		} else if (streetNumber === '' || !streetNumberRegEx.test(streetNumber)) {
			setInputFieldError('Straßenummer ungültig');
		} else if (isManufacturer && !nameRegEx.test(manufacturerName)) {
			setInputFieldError(
				'Herstellername fehlt'
			);
		} else if (isManufacturer &&  manufacturerImage?.size === 0) {
			setInputFieldError(
				'Herstellerbild fehlt'
			);
		} else if (!termsAccepted) {
			setInputFieldError(
				'Für den Abschluss der Registrierung zuerst die "AGB" akzeptieren.'
			);
		} else {
			axios.post(ENDPOINTS.register, {
				mail: mail,
				password: password,
				firstName: firstName,
				lastName: lastName,
				companyName: companyName,
				buyer: isBuyer,
				seller: isSeller,
				address: {
					title: addressTitle,
					country: country.code,
					zip: zip,
					city: city,
					street: street,
					streetNumber: streetNumber,
				},
				manufacturerName:manufacturerName,
				manufacturerImage: imagePreview?.data,
			})
				.then(function (response) {
					setInputFieldError('');
					navigate(APP_ROUTES.verifyFirstHint);
				})
				.catch((errors) => {
					setInputFieldError('Registrierung fehlgeschlagen');
				});
		}

		return false;
	};



	return (
		<>
			<div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-12 w-auto"
						src="/inopartLogo.jpeg"
						alt="Your Company"
					/>
					<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
						Registrierung
					</h2>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form
							action="src/components#"
							onSubmit={(event) => onRegisterButtonClick(event)}
						>
							<Divider title="Anmeldedaten" />

							<InputField
								wrapperclasses="py-2.5"
								type={InputFieldType.TEXT}
								value={mail}
								title="E-Mail-Adresse"
								onChange={(e)=>setMail(e.target.value)}/>

							<PasswordInputField
								wrapperClasses="py-2.5"
								title={'Passwort'}
								onChange={(e)=>setPassword(e.target.value)}
								value={password}
								type={'password'}/>

							<PasswordInputField
								wrapperClasses="py-2.5"
								title={'Passwort wiederholen'}
								onChange={(e)=>setPasswordRepeat(e.target.value)}
								value={passwordRepeat}
								type={'password'}/>

							<Divider title="Benutzerdaten"/>

							<div className="flex flex-row gap-4 py-2.5">
								<InputField
									type={InputFieldType.TEXT}
									value={firstName}
									title="Vorname"
									onChange={(e)=>setFirstName(e.target.value)}/>

								<InputField
									type={InputFieldType.TEXT}
									value={lastName}
									title="Nachname"
									onChange={(e)=>setLastName(e.target.value)}/>
							</div>

							<Divider title="Unternehmen" />

							<InputField
								wrapperclasses="py-2.5"
								type={InputFieldType.TEXT}
								value={companyName}
								title="Name des Unternehmens"
								placeholder=""
								onChange={(e)=>setCompanyName(e.target.value)}/>

							<CheckBox
								wrapperClasses="py-2.5"
								checked={isBuyer}
								onChange={(e) => setIsBuyer(e.target.checked)}
								label="Käufer"
								description="Das Unternehmen kauft Produkte von anderen Unternehmen."/>

							<CheckBox
								wrapperClasses="py-2.5"
								checked={isSeller}
								onChange={(e) => setIsSeller(e.target.checked)}
								label="Verkäufer"
								description="Das Unternehmen verkauft Produkte an andere Unternehmen."/>

							<ComboBox
								wrapperClasses="py-2"
								title="Land"
								items={CountrysISO3166_1.map(item => ({
									key: item.code,
									label: item.name,
									object: item
								}))}
								onChangeSelected={setCountry}/>

							<div className="flex flex-row gap-4 py-2.5">
								<InputField
									type={InputFieldType.TEXT}
									value={zip}
									title="Postleitzahl"
									onChange={(e)=>setZip(e.target.value)}/>

								<InputField
									type={InputFieldType.TEXT}
									value={city}
									title="Stadt"
									onChange={(e)=>setCity(e.target.value)}/>
							</div>

							<div className="flex flex-row gap-4 py-2.5">
								<InputField
									type={InputFieldType.TEXT}
									value={street}
									title="Straße"
									onChange={(e) => setStreet(e.target.value)}/>

								<InputField
									type={InputFieldType.TEXT}
									value={streetNumber}
									title="Hausnummer"
									onChange={(e) => setStreetNumber(e.target.value)}/>
							</div>

							<InputField
								wrapperclasses="py-2.5"
								type={InputFieldType.TEXT}
								value={addressTitle}
								title="Adresstitel"
								onChange={(e) => setAddressTitle(e.target.value)}/>


							<Divider title="Markenregistrierung" />

							<CheckBox
								wrapperClasses="py-2.5"
								checked={isManufacturer}
								onChange={(e) => setIsManufacturer(e.target.checked)}
								label="Das Unternehmen stellt eine eigene Marke dar"
							/>

							{isManufacturer &&
								<div>
									<InputField
										type={InputFieldType.TEXT}
										value={manufacturerName}
										title="Markenname"
										onChange={(e) => setManufacturerName(e.target.value)}
									/>

									<input
										type="file"
										onChange={(e) => setManufacturerImage(e.target.files ? e.target.files[0] : null)}
										style={{display: 'none'}}
										ref={imageFileRef}
										accept="image/png, image/jpeg"
									/>
									<div className={'pt-3 flex justify-center'}>
										<ImageButton
											onClick={() => imageFileRef.current?.click()}
											imageId={''}
											previewData={imagePreview.data}
											previewContentType={imagePreview.contentType}
										/>
									</div>
								</div>
							}

							<Divider wrapperClasses={'py-3'}/>

							<div className="flex items-center justify-between py-2.5">
								<div className="flex items-center">
									<input
										id="termsAccepted"
										name="termsAccepted"
										type="checkbox"
										className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-blue-500"
										onChange={(e) => setTermsAccepted(e.target.checked)}
									/>
									<label
										htmlFor="termsAccepted"
										className="ml-2 block text-sm text-gray-900"
									>
										<span>
											Ich aktzeptiere die
											<a
												href="/terms"
												className="font-medium text-blue-500 hover:text-indigo-500"
											>
												&nbsp; AGB
											</a>
										</span>
									</label>
								</div>
							</div>

							<div className="py-2.5">
								<button
									type={'submit'}
									className="flex w-full justify-center rounded-md border border-transparent bg-blue-800
									py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none
									focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									Registrieren
								</button>
							</div>
							{inputFieldError != '' && (
								<div className="flex justify-center">
									<div className="text-red-600 py-2">{inputFieldError}</div>
								</div>
							)}
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Registration;
