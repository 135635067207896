import {useEffect} from 'react';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import ChatMessageHistory from './ChatMessageHistory';
import ChatMessageInput from './ChatMessageInput';

import {
	useLazyGetChatQuery,
	useSubscribeChatWebsocketMutation,
	useUnsubscribeChatWebsocketMutation
} from '../../api/reduxAPI';

interface Props {
	chatID: string
	showInput:boolean
}


export default function Chat({chatID, showInput}: Props) {

	const errorHandling = useErrorHandling();

	const [getChat,{data:chat, isSuccess:chatFetchedSuccessfully}] = useLazyGetChatQuery();
	const [subscribeWebsocket] = useSubscribeChatWebsocketMutation();
	const [unsubsribeWebsocket] = useUnsubscribeChatWebsocketMutation();

	useEffect(() => {
		try {
			getChat({chatID});
		}
		catch (e:any){
			errorHandling(e);
		}
		return () => {
			try {
				unsubsribeWebsocket({chatID});
			}
			catch (e:any){
				errorHandling(e);
			}
		};
	}, []);

	useEffect(() => {
		if(chatFetchedSuccessfully){
			try {
				subscribeWebsocket({chatID});
			}
			catch (e:any){
				errorHandling(e);
			}
		}
	}, [chatFetchedSuccessfully]);

	return (
		<div className={'h-full flex flex-col gap-3'}>
			{chat &&
				<>
					<ChatMessageHistory
						chat={chat}
					/>
					{showInput &&
						<ChatMessageInput
							chat={chat}
						/>
					}

				</>
			}
		</div>
	);
}