import {useNavigate, useParams} from 'react-router-dom';
import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {passwordRegEx} from '../../utils/regex';
import axios from 'axios';
import Button, {ButtonType} from '../../components/button/Button';
import PasswordInputField from '../../components/inputField/PasswordInputField';
import {IconType} from '../../components/button/IconButton';
import {ENDPOINTS} from '../../api/endpoints';
import {APP_ROUTES} from '../../constants';

const PasswordUpdate = () => {
	const navigate = useNavigate();
	const {linkHash} = useParams<string>();

	const [password, setPassword] = useState<string>('');
	const [passwordRepeat, setPasswordRepeat] = useState<string>('');
	const [inputFieldError, setInputFieldError] = useState<string>('');


	useEffect(() => {
		axios.get(ENDPOINTS.passwordResetInfo + linkHash)
			.then(function (response){
				console.log('request passwordUpdateInfo successfull');
			})
			.catch(errors =>{
				console.log('request passwordUpdateInfo failed');
				navigate(APP_ROUTES.passwordResetExpired);
			});
	},[]);


	const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const onChangePasswordRepeat = (event: ChangeEvent<HTMLInputElement>) => {
		setPasswordRepeat(event.target.value);
	};

	const onFinishClick = async (event: FormEvent<HTMLFormElement>) =>{
		event.preventDefault();

		if (password == '') {
			setInputFieldError('Passwort fehlt');
		} else if (!passwordRegEx.test(password)) {
			setInputFieldError('Ungültiges Passwort: minimum 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Sonderzeichen, 1 Zahl, Länge von 8.');
		} else if (passwordRepeat != password) {
			setInputFieldError('Passwörter stimmen nicht überein.');
		} else {

			axios.put(ENDPOINTS.passwordUpdate + linkHash,{'password':password})
				.then(function (response){
					console.log('password updated successfull');
					console.log(response.status);
					navigate(APP_ROUTES.passwordUpdateConfirmation);
				})
				.catch(errors =>{
					console.log(errors.response.data.error);
					console.log('password-update failed');
					setInputFieldError(errors.response.data.error);
				});
		}

		return false;
	};

	return(
		<div className={'flex flex-col justify-center items-center h-full'}>
			<div className="w-80">

				<img
					className="mx-auto h-12 w-auto"
					src="/inopartLogo.jpeg"
					alt="Your Company"
				/>

				<div className="py-5 text-2xl">Passwort zurücksetzen</div>
				<div className="text-base">Bitte geben sie ihr neues Passwort ein.</div>

				<form onSubmit={(event) => onFinishClick(event)}>
					<div>
						<PasswordInputField wrapperClasses="py-2.5"  title={'Passwort'} placeholder={''} onChange={onChangePassword} value={password} type={'password'}/>
						<PasswordInputField wrapperClasses="py-2.5"  title={'Passwort wiederholen'} placeholder={''} onChange={onChangePasswordRepeat} value={passwordRepeat} type={'password'}/>
					</div>

					{inputFieldError != '' &&
					<div className="flex justify-center">
						<div className="text-red-600 pb-2">{inputFieldError}</div>
					</div>}

					<Button wrapperClasses="py-5" icon={IconType.CHECK} label={'Fertig'}/>
				</form>
			</div>
		</div>
	);
};

export default PasswordUpdate;