export const ENDPOINTS = {
	login: 'auth/web/login',
	register: 'auth/companies/register',
	inviteUser: 'auth/invite',
	finishAppRegistration: 'auth/invite/finish',
	finishCompanyRegistration: 'auth/companies/mailVerification',
	getUser: 'user',
	setOwnActiveStateUser: 'user/own/active',
	passwordReset: 'password/reset',
	passwordResetInfo:'password/reset/info/',
	passwordUpdate:'password/update/',
	listProducts:'products',
	listCompanyProducts:'products/fromCompany',
	categories:'categories',
	listAllCategories: 'categories/all',
	getCategory:'categories/',
	machines: 'machines',
	sellerCompanies: 'companies/seller',
	ownCompany: 'companies/own',
	updateUserCompany: 'companies',
	listOrderBundles: 'orderBundles',
	getOrderBundle: 'orderBundles/',
	listOrders: 'orders',
	getOrder: 'orders/',
	listFilters: 'filters',
	getFilter: 'filters/:fid',
	createProductWithVariants: 'products/withVariants',
	getFiles:'files',
	addFilesToProduct: 'products/:productId/file/add',
	listVariants: 'variants/list',
	listCompanyVariants: 'variants/list/fromCompany',
	createSaleOnVariants: 'sales/variants',

	file:{
		get:'file/{fileID}'
	},
	notifications:{
		list:'notifications',
		readAll:'notifications/read-all',
		read:'notifications/{notificationID}/read',
		subscribeWebsocket:'notifications/subscribe-websocket',
		unsubscribeWebsocket:'notifications/unsubscribe-websocket',
	},
	chats:{
		create:'chats',
		get:'chats/{chatID}',
		list:'chats',
		participate:'chats/{chatID}/participate',
		stopParticipate:'chats/{chatID}/stop-participate',
		subscribeWebsocket:'chats/{chatID}/subscribe-websocket',
		unsubscribeWebsocket:'chats/{chatID}/unsubscribe-websocket',
	},
	supportSessions:{
		create:'support-sessions',
		get:'support-sessions/{supportSessionID}',
		list:'support-sessions',
		participate: 'support-sessions/{supportSessionID}/participate',
		stopParticipate: 'support-sessions/{supportSessionID}/stop-participate',
		close: 'support-sessions/{supportSessionID}/close',
		inviteSupporter:'support-sessions/{supportSessionID}/invite-supporter/{userID}'
	},
	orders:{
		list:'orders',
		get:'orders/{orderID}'
	},
	products:{
		get:'products/{productID}',
		getVariant:'products/{productID}/variants/{variantID}'
	},
	companyPartnerships:{
		request: 'companyPartnerships',
		accept: 'companyPartnerships/{companyPartnershipID}/accept',
		listAsSeller: 'companyPartnerships/seller',
		listAsBuyer: 'companyPartnerships/buyer',
		delete:'companyPartnerships/{companyPartnershipID}'
	},
	companies:{
		listSellers:'companies/seller',
	},
	permissionGroups:{
		list: 'permissionGroups',
	},
	category:{
		hierarchy: 'categories/hierarchy',
	},
	categorySales:{
		list:'category-sales'
	},
	manufacturers:{
		list:'manufacturers',
		create:'manufacturers',
		update:'manufacturers/{manufacturerID}',
		delete:'manufacturers/{manufacturerID}/delete'
	}
};

export function endPointUserUnblock(userId:string):string{
	return 'user/' + userId + '/unblock';
}

export function endPointUserBlock(userId:string):string{
	return 'user/' + userId + '/block';
}

export function  endPointUserSetActiveState(userId:string):string {
	return 'user/' + userId + '/active';
}

export function endPointVariantSoftDelete(productId:string, variantId:string):string {
	return 'products/' + productId + '/variants/' + variantId + '/delete';
}

export function endPointGetImage(imageId:string):string{
	return process.env.REACT_APP_API_URL + '/image/' + imageId;
}

export function endPointProduct(productId:string):string {
	return 'products/' + productId;
}

export function endPointFilter(filterId:string):string {
	return 'filters/' + filterId;
}
