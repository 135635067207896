import DataTable, {TableColumn} from 'react-data-table-component';
import {CategorySale} from '../../../interfaces';
import FormatDate from '../../../utils/date';
import React, {useEffect, useState} from 'react';
import {API} from '../../../api/API';
import Button, {ButtonType} from '../../../components/button/Button';
import IconButton, {IconType} from '../../../components/button/IconButton';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';
import {PaginationDirection, usePaginationV2} from '../../../providers/usePaginationV2';
import {useLazyListCategorySalesQuery} from '../../../api/reduxAPI';
import {ListCategorySaleResp} from '../../../api/interfaces/categorySale';
import {MultiSearchBar, MultiSearchBarItem} from '../../../components/multiSearchBar/MultiSearchBar';


const SearchFields: MultiSearchBarItem[] = [
	{
		label: 'Name Kategorie',
		key: 'searchCategoryName'
	},
	{
		label: 'Name Partnerunternehmen',
		key: 'searchBuyerName'
	}
];

export default function CategorySaleOverview() {
	const errorHandling = useErrorHandling();

	const pagination = usePaginationV2({
		defaultSortField:'',
		defaultSortDirection:PaginationDirection.ASC,
		defaultStartToken:''
	});

	const [categorySales, setCategorySales] = useState<CategorySale[]>([]);
	const [listCategorySales] = useLazyListCategorySalesQuery();

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
	const [idToDelete, setIdToDelete] = useState<string | undefined>();


	useEffect(() => {
		try {
			const query:{[key:string]:string} = {};
			query[pagination.fetchParams.searchQuery?.key??'default'] = pagination.fetchParams.searchQuery?.value ?? '';

			const payload = listCategorySales({
				...query,
				pageSize:2,
				token: pagination.fetchParams.nextPageToken,
				sortPrimary: pagination.fetchParams.sortField,
				sortDirectionPrimary: pagination.fetchParams.sortDirection
			}).unwrap();

			payload.then((res:ListCategorySaleResp) => {

				pagination.setCurrentToken(res.nextPageToken);

				if (pagination.fetchParams.loadMore){
					setCategorySales(categorySales.concat(res.categorySales));
				} else {
					setCategorySales(res.categorySales);
				}
			});
		} catch (e:any){
			errorHandling(e);
		}
	}, [pagination.fetchParams]);

	const onSuccessDeleteModal = async (id: string) => {
		try {
			await API.CategorySale.softDelete(id);
			setIdToDelete(undefined);
			setIsDeleteModalVisible(false);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const colums: TableColumn<CategorySale>[] = [
		{
			name: 'Kategorie-Name',
			selector: row => row.category?.name ?? '',
			center: true,
			width: '180px'
		},
		{
			name: 'Reduziert %',
			selector: row => row.percentage / 100,
			center: true,
			width: '130px'
		},
		{
			name: 'Enddatum',
			selector: row => row.endDate ? FormatDate(row.endDate) : '-',
			center: true,
			width: '280px'
		},
		{
			name: 'Partnerunternehmen',
			selector: row => row.buyer?.name ?? '-',
			center: true,
			width: '280px'
		},
		{
			name: '',
			right: true,
			grow: 1,
			cell: (row) =>
				<IconButton
					onClick={() => {
						setIsDeleteModalVisible(true);
						setIdToDelete(row.id);
					}}
					icon={IconType.TRASH}
					title='Produkt löschen'/>
		},
	];

	return (
		<div className={'pt-10 pb-3 px-10'}>
			<MultiSearchBar
				searchFields={SearchFields}
				onChange={pagination.onSearchChange}/>

			<div className={'w-2/3'}>
				<DataTable
					noDataComponent={<div className={'pt-20'}>Keine Treffer vorhanden</div>}
					columns={colums}
					data={categorySales}/>
			</div>

			{!(pagination.currentToken === '@end' || pagination.currentToken === '') &&
				<div className={'flex justify-center'}>
					<Button
						wrapperClasses="py-5 self-center"
						label="Mehr laden"
						onClick={() => pagination.loadMore()}
					/>
				</div>
			}

			<ConfirmationModal
				isVisible={isDeleteModalVisible}
				onClose={() => {
					setIdToDelete(undefined);
					setIsDeleteModalVisible(false);
				}}
				onSuccess={() => onSuccessDeleteModal(idToDelete ?? '').then()}
				text={'Sind sie sicher, dass sie diese Variante löschen möchten?'}/>
		</div>
	);
}