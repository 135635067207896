import {ManufacturerState, Variant} from '../interfaces';


export function getEmptyVariant(): Variant {
	return structuredClone(emptyVariant);
}

const emptyVariant: Variant = {
	id: '',
	name: '',
	active: false,
	productId: '',
	amountAvailable: 0,
	articleNumber: '',
	category: undefined,
	categoryId: '',
	company: undefined,
	companyId: '',
	dataSheetIds: [],
	inheritDataSheets: true,
	dxfFileIds: [],
	inheritDxfFiles: true,
	imageIds: [],
	inheritIsActive: true,
	inheritStpFiles: true,
	inheritPlans: true,
	inheritLongDescription: true,
	inheritShortDescription: true,
	inheritProductPictures: true,
	longDescription: '',
	manufacturerArticleNumber: '',
	manufacturer: {name: '', id: '', countProducts: 0, imageId: '', manufacturerState:ManufacturerState.unchecked, creatorId:'', created:'', updated:''},
	creationDate: '',
	manufacturerId: '',
	planIds: [],
	stpFileIds: [],
	shortDescription: '',
	isDeleted: false,
	properties: [],
	price: 0,
	effectiveSale: undefined,
	effectiveSpecialCondition: undefined,
	variantSale: undefined,
	variantSaleSpecialConditions: [],
	variantSaleSpecialCondition: undefined,
	totalPrice: 0,
	isSale: false,
	variationProperty: undefined,
};