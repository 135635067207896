import {OrderComplaint} from '../../../../interfaces/supportSession';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {OrderDetails} from './OrderDetails';
import {ExpandableOrderPositionTable} from './ExpandableOrderPositionTable';
import {useGetOrderQuery} from '../../../../api/reduxAPI';


interface Props {
    orderComplaint:OrderComplaint;
}

export const OrderComplaintView = (props:Props) => {

	const {t} = useTranslation();

	const {data:order} = useGetOrderQuery({orderID : props.orderComplaint.order.id});

	return(
		<div className={'flex flex-col gap-5'}>

			<div className={'text-3xl underline'}>
				{t('supportSessions.sessionView.orderComplaintView.heading')}
			</div>

			{order &&
				<>
					<OrderDetails order={order}/>

					<div>
						<div className="text-2xl underline pb-5">
							{t('supportSessions.sessionView.orderComplaintView.orderPositions')}
						</div>
						<ExpandableOrderPositionTable
							data={order}
							concerningIDs={props.orderComplaint.concerningVariantIDs}
						/>
					</div>
				</>
			}
		</div>
	);
};