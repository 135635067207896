import {Outlet, useNavigate, useParams} from 'react-router-dom';
import React from 'react';
import {APP_ROUTES} from '../../../constants';
import {useTranslation} from 'react-i18next';


export default function SupportSessionAsSupportLayout() {
	const {id} = useParams<string>();
	const navigate = useNavigate();
	const {t} = useTranslation();

	return (
		<div className={'w-full h-full'}>
			<div className="flex justify-start pl-8">
				<h1
					className="text-2xl font-semibold text-gray-900 cursor-pointer"
					onClick={()=> navigate(APP_ROUTES.supportSessionsAsSupport)}
				>
					{t('supportSessions.viewHeading')} &nbsp;
				</h1>
				<h1 className="text-2xl font-semibold text-gray-900">&#8594; &nbsp; </h1>
				<h1 className="text-2xl font-semibold text-gray-900">{id}</h1>
			</div>
			<div className={'w-full h-full px-8 py-3'}>
				<Outlet/>
			</div>
		</div>
	);
}