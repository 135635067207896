import {Manufacturer} from '../../../../interfaces';
import {ModalV2} from '../../../../components/modal/ModalV2';
import ImageButton from '../../../../components/button/ImageButton';
import InputField, {InputFieldType} from '../../../../components/inputField/InputField';
import Button from '../../../../components/button/Button';
import React, {useEffect, useState} from 'react';
import {useErrorHandling} from '../../../../providers/error/ErrorProvider';
import {useCreateManufacturerMutation, useUpdateManufacturerMutation} from '../../../../api/reduxAPI';
import {toast} from 'sonner';


interface Image {
	data:string
	contentType:string
}

interface Props {
	isOpen: boolean,
	onClose: () => void,
	manufacturer:Manufacturer
}

export const EditManufacturerModal = (props: Props) => {
	const errorHandling = useErrorHandling();

	const [manufacturerName, setManufacturerName] = useState<string>(props.manufacturer.name);

	const imageFileRef = React.useRef<HTMLInputElement>(null);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [imagePreview, setImagePreview] = useState<Image>({data:'',contentType:''});

	const [updateManufacturer] = useUpdateManufacturerMutation();

	const onUpdate = async () => {

		console.log('imagePreview', imagePreview.data);
		console.log('imageFile', imageFile);
		console.log('imageFileRef',imageFileRef);

		if(manufacturerName === '') {
			toast.error('Herstellername fehlt');
		} else if(imagePreview?.data === '' && props.manufacturer.imageId === ''){
			toast.error('Herstellerbild fehlt');
		} else {
			try {
				await updateManufacturer({
					manufacturerID: props.manufacturer.id,
					name: manufacturerName,
					imageData: imagePreview?.data?? undefined
				}).unwrap();
				toast.success('Marke erfolgreich aktualisiert. Bitte warten sie auf Verifizierung von Seiten Inoparts.');
				props.onClose();
			} catch (e:any){
				errorHandling(e);
			}
		}
	};

	useEffect(() => {
		if (imageFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrB64 = (reader.result as string).split(',');
				if (arrB64.length == 2){
					setImagePreview({data:arrB64[1], contentType:imageFile.type});
				}
			};
			reader.readAsDataURL(imageFile);
		}
	}, [imageFile]);

	return(

		<ModalV2 isOpen={props.isOpen} handleClose={props.onClose}>
			<div className={'flex flex-col gap-5 items-center'}>

				<ImageButton
					onClick={() => imageFileRef.current?.click()}
					imageId={props.manufacturer.imageId}
					previewData={imagePreview.data}
					previewContentType={imagePreview.contentType}
				/>

				<InputField
					title={'Markenname'}
					value={manufacturerName}
					type={InputFieldType.TEXT}
					onChange={(e) => setManufacturerName(e.target.value)}
				/>

				<input
					type="file"
					onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
					style={{display: 'none'}}
					ref={imageFileRef}
					accept="image/png, image/jpeg"
				/>

				<Button
					onClick={() => onUpdate()}
					label={'Marke Updaten'}
				/>
			</div>
		</ModalV2>
	);
};