import React, {ChangeEvent, FormEvent, useState} from 'react';
import InputField, {InputFieldType} from '../../components/inputField/InputField';
import Button, {ButtonType} from '../../components/button/Button';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {mailRegEx} from '../../utils/regex';
import {ENDPOINTS} from '../../api/endpoints';
import {APP_ROUTES} from '../../constants';



const PasswordReset = () => {
	const navigate = useNavigate();
	
	const [mail,setMail] = useState<string>('');
	const [inputFieldError, setInputFieldError] = useState<string>('');

	const onChangeMail = (event: ChangeEvent<HTMLInputElement>) => {
		setMail(event.target.value);
	};


	const onResetPassword = async (event: FormEvent<HTMLFormElement>) =>{
		event.preventDefault();

		if (mail == '') {
			setInputFieldError('E-mail fehlt');
		} else if (!mailRegEx.test(mail)) {
			setInputFieldError('E-Mail ungültig');
		} else {

			axios.post(ENDPOINTS.passwordReset,{'mail':mail})
				.then(function (response){
					console.log('password reset successfull');
					navigate(APP_ROUTES.passwordResetConfirmation);
				})
				.catch(errors =>{
					console.log('password-reset failed');
					setInputFieldError('password reset request failed');
				});
		}

		return false;
	};

	return(
		<div className={'flex flex-col justify-center items-center h-full'}>
			<img
				className="mx-auto h-12 w-auto"
				src="/inopartLogo.jpeg"
				alt="Your Company"
			/>

			<form onSubmit={(event => onResetPassword(event))}>
				<div className="flex flex-col">
					<div className="py-5 content-center text-2xl">Passwort vergessen?</div>

					<div className="text-base">Bitte geben sie ihre E-Mail-Adresse ein, um ihr Passwort zurück zu setzen.</div>
					<InputField wrapperclasses="py-5" title={'Mail'} placeholder={''} onChange={onChangeMail} value={mail} type={InputFieldType.TEXT}/>

					{inputFieldError != '' &&
							<div className="flex justify-center">
								<div className="text-red-600 pb-2">{inputFieldError}</div>
							</div>}
					<Button wrapperClasses="" label="Passwort zurücksetzen" className="py-5 flex w-full
					rounded-md border border-transparent bg-blue-800 py-5 px-4 text-sm font-medium text-white shadow-sm
					 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"/>
				</div>
			</form>

		</div>
	);
};

export default PasswordReset;
