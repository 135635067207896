import InputField, {InputFieldType} from '../../../../components/inputField/InputField';
import ImageButton from '../../../../components/button/ImageButton';
import Button from '../../../../components/button/Button';
import {ModalV2} from '../../../../components/modal/ModalV2';
import React, {useEffect, useState} from 'react';
import {useCreateManufacturerMutation} from '../../../../api/reduxAPI';
import {toast} from 'sonner';
import {useErrorHandling} from '../../../../providers/error/ErrorProvider';

interface Image {
	data:string
	contentType:string
}

interface Props {
	isOpen: boolean,
	onClose: () => void,
}

export const AddManufacturerModal = (props:Props) => {

	const errorHandling = useErrorHandling();

	const [manufacturerName, setManufacturerName] = useState<string>('');

	const imageFileRef = React.useRef<HTMLInputElement>(null);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [imagePreview, setImagePreview] = useState<Image>({data:'',contentType:''});

	const [createManufacturer] = useCreateManufacturerMutation();

	const onRegister = async () => {
		if(manufacturerName === '') {
			toast.error('Herstellername fehlt');
		} else if(imagePreview?.data === ''){
			toast.error('Herstellerbild fehlt');
		} else {
			try {
				await createManufacturer({name:manufacturerName, imageData:imagePreview?.data}).unwrap();
				toast.success('Marke erfolgreich erstellt. Bitte warten sie auf Verifizierung von Seiten Inoparts.');
				props.onClose();
			} catch (e:any){
				errorHandling(e);
			}
		}
	};

	useEffect(() => {
		if (imageFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrB64 = (reader.result as string).split(',');
				if (arrB64.length == 2){
					setImagePreview({data:arrB64[1], contentType:imageFile.type});
				}
			};
			reader.readAsDataURL(imageFile);
		}
	}, [imageFile]);

	return(
		<ModalV2
			isOpen={props.isOpen}
			handleClose={props.onClose}
		>
			<div className={'flex flex-col gap-5 items-center'}>

				<ImageButton
					onClick={() => imageFileRef.current?.click()}
					previewData={imagePreview.data}
					previewContentType={imagePreview.contentType}
				/>

				<InputField
					title={'Markenname'}
					value={manufacturerName}
					type={InputFieldType.TEXT}
					onChange={(e) => setManufacturerName(e.target.value)}
				/>

				<input
					type="file"
					onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
					style={{display: 'none'}}
					ref={imageFileRef}
					accept="image/png, image/jpeg"
				/>

				<Button
					onClick={() => onRegister()}
					label={'Marke Registrieren'}
				/>
			</div>
		</ModalV2>
	);
};