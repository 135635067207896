

export const APP_ROUTES = {
	login: '/login',

	dashboard:'/dashboard',

	passwordReset:'/password/reset',
	passwordResetExpired:'/password/reset/expired',
	passwordResetConfirmation:'/password/reset/confirmation',
	passwordUpdate:'/password/update/:linkHash',
	passwordUpdateConfirmation:'/password/update/confirmation',

	register:'/register',
	setPasswordInvitedUser:'/invitation/web/finish/:linkHash',
	mailVerificationRedirect:'/mailVerification/web/:linkHash',
	verifyFirstHint:'/verifyFirst',

	products: '/sell/products',

	createVariantSale: '/sell/sale/variants/create',
	manageVariantSale: '/sell/sale/variants/manage',

	createVariantSpecialCondition: '/sell/specialCondition/variants/create',
	manageVariantSpecialCondition: '/sell/specialCondition/variants/manage',

	createCategorySale: '/sell/sale/category/create',
	manageCategorySale: '/sell/sale/category/manage',

	createProduct: '/sell/products/create/category/:categoryId',

	orders: '/buy/orders',
	orderOverview:'/buy/orders/:bundleNumber/:bundleId/:orderNumber/:orderId',

	editProductWithoutVariants: '/sell/products/:productId/edit/withoutVariants',
	editProductWithVariants: '/sell/products/:productId/edit/withVariants',

	selectCategory: '/sell/products/select_category',

	detailedProduct: '/sell/products/:productId/:variantId/:variantName',
	detailedVariant: '/sell/products/:productId/:variantId/:productName/:variantPropertyName/:variantValue',

	variantsOverview: '/sell/products/:productId/:productName',

	companyInformation: '/company/information',
	userManagement: '/company/members',

	manufcturerBrand: '/company/brand',

	partnershipSelection: '/partnerships/selection',
	partnershipRequests: '/partnerships/requests',
	partnershipsAsBuyer: '/partnerships/sellers',
	partnershipsAsSeller: '/partnerships/buyers',

	categoryTree:'categoryTree',

	supportSessionsAsSupport: '/support-sessions/as-support',
	supportSessionsAsRequester: '/support-sessions/as-requester',

	supportSessionAsSupport: '/support-sessions/as-support/:id',
	supportSessionAsRequester: '/support-sessions/as-requester/:id',
	getSupportSessionAsSupportRoute: function (id:string):string{return '/support-sessions/as-support/' + id;},
	getSupportSessionAsRequesterRoute: function (id:string):string{return '/support-sessions/as-requester/' + id;},
};

export function routeOrderOverview(bundleNumber:string, bundleId:string, orderNumber:string, orderId:string):string{
	return 	'/buy/orders/' + bundleNumber + '/' + bundleId + '/' + orderNumber  + '/' + orderId;
}

export function routePasswordUpdate(linkHash:string):string{
	return 	'/password/update/' + linkHash;
}

export function routeMailVerificationRedirect(linkHash:string):string{
	return '/mailVerification/web/' + linkHash;
}

export function routeCreateProduct(categoryId:string):string{
	return '/sell/products/create/category/' + categoryId;
}

export function routeDetailedProduct(productId:string, variantId:string, variantName:string):string {
	return '/sell/products/' + productId + '/' + variantId + '/' + variantName;
}

export function routeDetailedVariant(productId:string, productName:string, variantId:string, variantPropertyName:string, variantValue:string|number):string{
	return '/sell/products/' + productId + '/' + variantId  + '/' + productName + '/' + variantPropertyName + '/' + variantValue;
}

export function routeVariantsOverview(productId:string, productName:string) {
	return '/sell/products/' + productId + '/' + productName;
}

export function routeEditProductWithoutVariants(productId:string) {
	return '/sell/products/' + productId + '/edit/withoutVariants';
}

export function routeEditProductWithVariants(productId:string) {
	return '/sell/products/' + productId + '/edit/withVariants';
}
