
export const passwordRegEx = new RegExp(
	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
);

export const mailRegEx = new RegExp(/^[A-ZäöüÄÖÜß0-9._%+-]+@[A-ZäöüÄÖÜß0-9.-]+.[A-Z]{2,4}$/i,);

export const propertyInputRegEx = new RegExp(/^-?[a-zA-Z0-9äöüÄÖÜß]+([.,'`±ø~*´_ ‘+/-][a-zA-Z0-9äöüÄÖÜß]+)*$/);

export const numberInputRegEx = new RegExp(/^-?\d+(\.|,)?\d*$/);

// Andis Regexes
export const nameRegEx = new RegExp('^(?=.*[a-zA-ZäöüßÄÖÜ])[a-zA-ZäöüßÄÖÜ\\s]+$');
export const streetRegEx = new RegExp('^([a-zA-ZäöüÄÖÜß.\\s/-]+)$');
export const streetNumberRegEx = new RegExp('^(\\d+\\w*)$');
export const zipRegEx = new RegExp('^(\\d+)$');
export const cityRegEx = new RegExp('^([a-zA-ZäöüÄÖÜß\\s/.,-]+)$');
export const addressTitileRegex = /^\S.*$/;
export const phoneRegEx = new RegExp(/^[+]?[0-9]{10,}$/);