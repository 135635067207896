
export type Manufacturer = {
    id            :string
    name          :string
    imageId       :string
    countProducts :number
    manufacturerState: ManufacturerState
    creatorId     :string
    companyId?    :string
    created: string
    updated: string
}

export enum ManufacturerState {
    unchecked = 'UNCHECKED',
    active = 'ACTIVE',
    hidden = 'HIDDEN'
}