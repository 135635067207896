import React, {useEffect, useState} from 'react';
import ComboBox from '../comboBox/ComboBox';
import InputField from '../inputField/InputField';
import {SearchQuery} from '../../providers/usePaginationV2';


export interface MultiSearchBarItem {
	label: string
	key: string
}

interface Props {
	searchFields: MultiSearchBarItem[]
	onChange: (searchQuery:SearchQuery) => void
}

export const MultiSearchBar = (props: Props) => {

	const [selectedSearchField, setSelectedSearchField] = useState<MultiSearchBarItem>(props.searchFields[0]);

	const [searchValue, setSearchValue] = useState<string>('');

	// to prevent trigger fetch with initial empty string
	const [oldSearchValue, setOldSearchValue] = useState<string>('');

	useEffect(() => {
		setSearchValue('');
	}, [selectedSearchField]);

	useEffect(() => {
		if(oldSearchValue !== searchValue){
			setOldSearchValue(searchValue);
			const timeout = setTimeout(() => props.onChange({key:selectedSearchField.key, value:searchValue}), 400);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [searchValue]);

	return(
		<div className={'flex gap-3'}>
			<ComboBox
				wrapperClasses={'w-1/5'}
				title={'Suchfeld'}
				selected={{...selectedSearchField, object:selectedSearchField}}
				items={props.searchFields.map(object => ({key: object.key, label: object.label, object: object}))}
				onChangeSelected={setSelectedSearchField}
			/>

			<div className={'w-1/4'}>
				<InputField
					title={'Wert'}
					value={searchValue}
					onChange={(e)=>setSearchValue(e.target.value)}
				/>
			</div>
		</div>
	);
};