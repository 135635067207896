import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Login from './features/login/Login';
import Registration from './features/registration/components/Registration';
import Dashboard from './features/dashboard/Dashboard';
import BaseLayout from './components/BaseLayout';
import UserManagementHeader from './features/userAdministration/components/UserManagementHeader';
import DashboardHeader from './features/dashboard/DashboardHeader';
import React from 'react';
import {ProtectedRoutes} from './providers/auth/ProtectedRoutes';
import MailVerificationRedirect from './features/registration/components/MailVerificationRedirect';
import VerifyFirstHint from './features/registration/components/VerifyFirstHint';
import PasswordResetSentConfirmation from './features/passwordReset/PasswordResetConfirmation';
import PasswordUpdate from './features/passwordReset/PasswordUpdate';
import PasswordReset from './features/passwordReset/PasswordReset';
import PasswordUpdateConfirmation from './features/passwordReset/PasswordUpdateConfirmation';
import PasswordResetExpired from './features/passwordReset/PasswordResetExpired';
import ProductTableHeader from './features/products/productTable/components/ProductTableHeader';
import InformationLayout from './features/company/information/InformationLayout';
import Information from './features/company/information/Information';
import OrderBundlesTable from './features/orderBundles/OrderBundlesTable';
import OrderBundles from './features/orderBundles/OrderBundles';
import Order from './features/orderBundles/Order';
import OrderOverview from './features/orderBundles/OrderOverview';
import SetPasswordInvitedUser from './features/registration/components/SetPasswordInvitedUser';
import WithVariantsOverview from './features/products/detailedView/WithVariantsOverview';
import {APP_ROUTES} from './constants';
import ProductTable from './features/products/productTable/ProductTable';
import Details from './features/products/detailedView/Details';
import DetailedProductHeader from './features/products/detailedView/components/DetailedProductHeader';
import DetailedVariantHeader from './features/products/detailedView/components/DetailedVariantHeader';
import WithVariantsOverviewHeader from './features/products/detailedView/components/WithVariantsOverviewHeader';
import CreateProduct from './features/products/management/create/CreateProduct';
import CreateProductHeader from './features/products/management/create/components/CreateProductHeader';
import SelectCategoryHeader from './features/products/management/create/components/SelectCategoryHeader';
import SelectCategory from './features/products/management/create/SelectCategory';
import EditProductWithoutVariants from './features/products/management/edit/EditProductWithoutVariants';
import EditProductHeader from './features/products/management/edit/components/EditProductHeader';
import EditProductWithVariants from './features/products/management/edit/EditProductWithVariants';
import PartnershipsAsBuyer from './features/companyPartnerships/PartnershipsAsBuyer';
import PartnershipsAsBuyerHeader from './features/companyPartnerships/components/PartnershipsAsBuyerHeader';
import PartnershipsAsSeller from './features/companyPartnerships/PartnershipsAsSeller';
import PartnershipsAsSellerHeader from './features/companyPartnerships/components/PartnershipsAsSellerHeader';
import IncomingPartnershipRequestHeader
	from './features/companyPartnerships/components/IncomingPartnershipRequestHeader';
import IncomingPartnershipRequests from './features/companyPartnerships/IncomingPartnershipRequests';
import PartnershipSelectionHeader from './features/companyPartnerships/components/PartnershipSelectionHeader';
import PartnershipSelection from './features/companyPartnerships/PartnershipSelection';
import {NotificationProvider} from './providers/notification/NotificationProvider';
import CreateVariantSale from './features/sale/variant/normalSale/create/CreateVariantSale';
import CreateCategorySale from './features/sale/category/CreateCategorySale';
import CategorySaleOverviewHeader from './features/sale/category/component/CategorySaleOverviewHeader';
import CreateCategorySaleHeader from './features/sale/category/component/CreateCategorySaleHeader';
import CreateVariantSaleHeader from './features/sale/variant/normalSale/create/components/CreateVariantSaleHeader';
import CategorySaleOverview from './features/sale/category/CategorySaleOverview';
import ManageVariantSaleHeader from './features/sale/variant/normalSale/manage/components/ManageVariantSaleHeader';
import ManageVariantSale from './features/sale/variant/normalSale/manage/ManageVariantSale';
import {ErrorProvider} from './providers/error/ErrorProvider';
import CreateVariantSpecialCondition
	from './features/sale/variant/specialCondition/create/CreateVariantSpecialCondition';
import CreateVariantSpecialConditionHeader
	from './features/sale/variant/specialCondition/create/component/CreateVariantSpecialConditionHeader';
import ManageVariantSpecialConditionHeader
	from './features/sale/variant/specialCondition/manage/component/ManageVariantSpecialConditionHeader';
import ManageVariantSpecialCondition
	from './features/sale/variant/specialCondition/manage/ManageVariantSpecialCondition';
import UserManagement from './features/userAdministration/UserManagement';
import SupportSessionsAsSupporter from './features/supportSessions/supportSessionList/SupportSessionsAsSupporter';
import SupportSessionAsSupportLayout from './features/supportSessions/supportSessionView/SupportSessionAsSupportLayout';
import {Toaster} from 'sonner';
import {Provider} from 'react-redux';
import {store} from './store/store';
import SupportSessionsAsRequester from './features/supportSessions/supportSessionList/SupportSessionsAsRequester';
import SupportSessionsAsRequesterLayout
	from './features/supportSessions/supportSessionList/SupportSessionsAsRequesterLayout';
import SupportSessionAsSupport from './features/supportSessions/supportSessionView/SupportSessionAsSupport';
import SupportSessionAsRequesterLayout
	from './features/supportSessions/supportSessionView/SupportSessionAsRequesterLayout';
import SupportSessionAsRequester from './features/supportSessions/supportSessionView/SupportSessionAsRequester';
import CategoryTreeLayout from './features/categoryTree/CategoryTreeLayout';
import {CategoryTree} from './features/categoryTree/CategoryTree';
import ManufacturersLayout from './features/company/manufacturers/ManufacturersLayout';
import {Manufacturers} from './features/company/manufacturers/Manufacturers';
import { WecksocketConnectionProvider } from './providers/websocket/WebsocketProvider';

function App() {
	return (
		<Provider store={store}>
			<Toaster position={'top-right'} richColors/>
			<WecksocketConnectionProvider>
				<ErrorProvider>
					<BrowserRouter>
						<Routes>
							<Route element={<BaseLayout/>}>
								<Route path="" element={<Login/>}/>
								<Route path="/" element={<Login/>}/>

								<Route
									path={APP_ROUTES.login}
									element={<Login/>}/>

								<Route
									path={APP_ROUTES.passwordReset}
									element={<PasswordReset/>}/>

								<Route
									path={APP_ROUTES.passwordResetExpired}
									element={<PasswordResetExpired/>}/>

								<Route
									path={APP_ROUTES.passwordResetConfirmation}
									element={<PasswordResetSentConfirmation/>}/>

								<Route
									path={APP_ROUTES.passwordUpdate}
									element={<PasswordUpdate/>}/>

								<Route
									path={APP_ROUTES.passwordUpdateConfirmation}
									element={<PasswordUpdateConfirmation/>}/>

								<Route
									path={APP_ROUTES.register}
									element={<Registration/>}/>

								<Route
									path={APP_ROUTES.setPasswordInvitedUser}
									element={<SetPasswordInvitedUser/>}/>

								<Route
									path={APP_ROUTES.mailVerificationRedirect}
									element={<MailVerificationRedirect/>}/>

								<Route
									path={APP_ROUTES.verifyFirstHint}
									element={<VerifyFirstHint/>}/>

								<Route
									path="*"
									element={<Navigate to="/" replace/>}
								/>
							</Route>

							<Route element={<NotificationProvider><ProtectedRoutes/></NotificationProvider>}>
								<Route path="/" element={<Dashboard/>}>
									<Route index element={<DashboardHeader/>}/>


									<Route
										path={APP_ROUTES.orders}
										element={<OrderBundles/>}>

										<Route index element={<OrderBundlesTable/>}/>
									</Route>

									<Route
										path={APP_ROUTES.orderOverview}
										element={<Order/>}>

										<Route index element={<OrderOverview/>}/>
									</Route>

									<Route
										path={APP_ROUTES.products}
										element={<ProductTableHeader/>}>

										<Route index element={<ProductTable/>}/>
									</Route>

									<Route
										path={APP_ROUTES.createVariantSale}
										element={<CreateVariantSaleHeader/>}>

										<Route index element={<CreateVariantSale/>}/>
									</Route>

									<Route
										path={APP_ROUTES.manageVariantSale}
										element={<ManageVariantSaleHeader/>}>

										<Route index element={<ManageVariantSale/>}/>
									</Route>

									<Route
										path={APP_ROUTES.createVariantSpecialCondition}
										element={<CreateVariantSpecialConditionHeader/>}>

										<Route index element={<CreateVariantSpecialCondition/>}/>
									</Route>

									<Route
										path={APP_ROUTES.manageVariantSpecialCondition}
										element={<ManageVariantSpecialConditionHeader/>}>

										<Route index element={<ManageVariantSpecialCondition/>}/>
									</Route>

									<Route
										path={APP_ROUTES.createCategorySale}
										element={<CreateCategorySaleHeader/>}>

										<Route index element={<CreateCategorySale/>}/>
									</Route>

									<Route
										path={APP_ROUTES.manageCategorySale}
										element={<CategorySaleOverviewHeader/>}>

										<Route index element={<CategorySaleOverview/>}/>
									</Route>

									<Route
										path={APP_ROUTES.variantsOverview}
										element={<WithVariantsOverviewHeader/>}>

										<Route index element={<WithVariantsOverview/>}/>
									</Route>

									<Route
										path={APP_ROUTES.detailedVariant}
										element={<DetailedVariantHeader/>}>

										<Route index element={<Details/>}/>
									</Route>

									<Route
										path={APP_ROUTES.detailedProduct}
										element={<DetailedProductHeader/>}>

										<Route index element={<Details/>}/>
									</Route>

									<Route
										path={APP_ROUTES.selectCategory}
										element={<SelectCategoryHeader/>}>

										<Route index element={<SelectCategory/>}/>
									</Route>

									<Route
										path={APP_ROUTES.createProduct}
										element={<CreateProductHeader/>}>

										<Route index element={<CreateProduct/>}/>
									</Route>

									<Route
										path={APP_ROUTES.editProductWithoutVariants}
										element={<EditProductHeader/>}>

										<Route index element={<EditProductWithoutVariants/>}/>
									</Route>

									<Route
										path={APP_ROUTES.editProductWithVariants}
										element={<EditProductHeader/>}>

										<Route index element={<EditProductWithVariants/>}/>
									</Route>

									<Route
										path={APP_ROUTES.companyInformation}
										element={<InformationLayout/>}>

										<Route index element={<Information/>}/>
									</Route>

									<Route
										path={APP_ROUTES.userManagement}
										element={<UserManagementHeader/>}>

										<Route index element={<UserManagement/>}/>
									</Route>

									<Route
										path={APP_ROUTES.manufcturerBrand}
										element={<ManufacturersLayout/>}>

										<Route index element={<Manufacturers/>}/>
									</Route>

									<Route
										path={APP_ROUTES.partnershipSelection}
										element={<PartnershipSelectionHeader/>}>

										<Route index element={<PartnershipSelection/>}/>
									</Route>

									<Route
										path={APP_ROUTES.partnershipRequests}
										element={<IncomingPartnershipRequestHeader/>}>

										<Route index element={<IncomingPartnershipRequests/>}/>
									</Route>

									<Route
										path={APP_ROUTES.partnershipsAsBuyer}
										element={<PartnershipsAsBuyerHeader/>}>

										<Route index element={<PartnershipsAsBuyer/>}/>
									</Route>

									<Route
										path={APP_ROUTES.partnershipsAsSeller}
										element={<PartnershipsAsSellerHeader/>}>

										<Route index element={<PartnershipsAsSeller/>}/>
									</Route>

									<Route
										path={APP_ROUTES.supportSessionsAsSupport}
										element={<SupportSessionAsSupportLayout/>}>

										<Route index element={<SupportSessionsAsSupporter/>}/>
									</Route>

									<Route
										path={APP_ROUTES.supportSessionsAsRequester}
										element={<SupportSessionsAsRequesterLayout/>}>
										<Route index element={<SupportSessionsAsRequester/>}/>
									</Route>

									<Route
										path={APP_ROUTES.supportSessionAsSupport}
										element={<SupportSessionAsSupportLayout/>}>
										<Route index element={<SupportSessionAsSupport/>}/>
									</Route>

									<Route
										path={APP_ROUTES.supportSessionAsRequester}
										element={<SupportSessionAsRequesterLayout/>}>
										<Route index element={<SupportSessionAsRequester/>}/>
									</Route>

									<Route
										path={APP_ROUTES.categoryTree}
										element={<CategoryTreeLayout/>}>
										<Route index element={<CategoryTree/>}/>
									</Route>

								</Route>
							</Route>
						</Routes>
					</BrowserRouter>
				</ErrorProvider>
			</WecksocketConnectionProvider>
		</Provider>
	);
}

export default App;
