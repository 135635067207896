import {SideDrawer} from '../../../components/sideDrawer/SideDrawer';
import {CategoryHierarchy} from '../../../interfaces/categoryHierarchy';
import {Filter} from '../../../interfaces';
import {useTranslation} from 'react-i18next';

interface Props {
	hierarchy: CategoryHierarchy | undefined
	isVisible: boolean
	onClose: () => void
}

export const CategoryDetailSideDrawer = (props:Props) => {

	const {t} = useTranslation();
	
	return(
		<>
			<SideDrawer
				titel={props.hierarchy?.name ?? ''}
				isVisible={props.isVisible}
				onClose={props.onClose}
			>
				<div>
					<div className={'text-xl font-bold pb-5'}>
						{t('categoryHierarchy.detailedView.ID')}: {props.hierarchy?.id}
					</div>
					<div className={'text-xl font-bold pb-5'}>
						{t('categoryHierarchy.detailedView.filter')}
					</div>

					<div className={'flex flex-col gap-3'}>
						{props.hierarchy &&
							props.hierarchy.filters?.map((item: Filter) =>
								<div className="flex flex-col  rounded-md bg-gray-100 px-2 py-1 font-normal text-black text-xl ring-1 ring-inset ring-gray-500/10">
									<span className={'font-bold'}>
										{item.name}
									</span>
									<span>
										{t('categoryHierarchy.detailedView.ID')}: {item.id}
									</span>
									{item.unit &&
										<span>
											{t('categoryHierarchy.detailedView.unit')}: {item.unit}
										</span>
									}
								</div>
							)
						}
					</div>
				</div>
			</SideDrawer>
		</>
	)
	;
};