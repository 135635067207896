import {useNavigate, useParams} from 'react-router-dom';
import {useAuth} from '../../../providers/auth/useAuth';
import React, {FormEvent, useState} from 'react';
import axios from 'axios';
import {passwordRegEx} from '../../../utils/regex';
import PasswordInputField from '../../../components/inputField/PasswordInputField';
import Button, {ButtonType} from '../../../components/button/Button';
import {ENDPOINTS} from '../../../api/endpoints';
import {APP_ROUTES} from '../../../constants';


const SetPasswordInvitedUser = () => {
	const navigate = useNavigate();
	const auth = useAuth();
	const {linkHash} = useParams<string>();

	const [password, setPassword] = useState<string>('');
	const [passwordRepeat, setPasswordRepeat] = useState<string>('');
	const [inputFieldError, setInputFieldError] = useState<string>('');


	const onRegisterButtonClick = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (password == '' || !passwordRegEx.test(password)) {
			setInputFieldError(
				'Ungültiges Passwort: Minimum 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Sonderzeichen, 1 Zahl und eine Mindestlänge von 8 Zeichen.'
			);
		} else if (passwordRepeat == '') {
			setInputFieldError('Bitte wiederholen sie das Passwort');
		} else if (passwordRepeat != password) {
			setInputFieldError('Passwörter stimmen nicht überein');
		} else {
			axios.post(ENDPOINTS.finishAppRegistration,
				{
					deeplinkHash: linkHash,
					password: password
				})
				.then(function (response) {
					setInputFieldError('');
					navigate(APP_ROUTES.login);
				})
				.catch((errors) => {
					setInputFieldError('Registrierung fehlgeschlagen');
				});
		}
	};

	return(
		<div>
			<div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-12 w-auto"
						src="/inopartLogo.jpeg"
						alt="Your Company"
					/>
					<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
						Registrierung
					</h2>
				</div>


				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form
							action="src/components#"
							onSubmit={(event) => onRegisterButtonClick(event)}
						>


							<PasswordInputField
								wrapperClasses="py-2.5"
								title={'Passwort'}
								onChange={(e)=>setPassword(e.target.value)}
								value={password}
								type={'password'}/>

							<PasswordInputField
								wrapperClasses="py-2.5"
								title={'Passwort wiederholen'}
								onChange={(e)=>setPasswordRepeat(e.target.value)}
								value={passwordRepeat}
								type={'password'}/>

							<Button
								wrapperClasses="pt-5 flex justify-center"
								label="Registrierung abschließen"/>

							{inputFieldError != '' && (
								<div className="pt-5 flex justify-center">
									<div className="text-red-600 py-2">{inputFieldError}</div>
								</div>
							)}
						</form>
					</div>



				</div>
			</div>
		</div>
	);
};

export default SetPasswordInvitedUser;