import {propertyInputRegEx, VariantBuilder, VariantBuilderState} from '../../../utils';
import {Property} from '../../../interfaces';
import {Constants} from '../../../constants';
import Necessity = Constants.Necessity;


export function checkProperties(properties:Property[]):boolean {
	let propertiesComplete = true;

	for (let i = 0; i < properties.length; i++) {
		if (!propertyInputRegEx.test(String(properties[i].value)) ) {
			propertiesComplete = false;
		}
	}

	return propertiesComplete;
}

export function getAllVariationPropertyValues(variantBuilderMap: Map<string, VariantBuilder>): (string | number)[] {
	let values: (string | number)[] = [];

	variantBuilderMap.forEach((builder, key) => {

		if (builder.getState() !== VariantBuilderState.delete) {

			const value: string | number | undefined = builder.getDisplayed().properties
				.find((elem) => elem.necessity === Necessity.Variation)?.value;

			if (value) {
				values = values.concat(value);
			}
		}

	});
	return values;
}