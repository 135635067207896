import {useState} from 'react';

export enum PaginationDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}

export interface UsePaginationV2Props  {
	defaultStartToken:string
	defaultSortField:string
	defaultSortDirection:PaginationDirection
}

export interface SearchQuery {
	key:string
	value:string
}

type FetchParams = {
	nextPageToken:string
	sortField:string
	sortDirection:string
	loadMore:boolean
	searchQuery?:SearchQuery
}

type UsePaginationV2 = (props:UsePaginationV2Props) => {

	// loads more items with the nextPageToken
	loadMore: () => void

	currentToken:string
	setCurrentToken: (token:string)=> void

	onSortChange:(field:string, direction:string) => void

	onSearchChange:(item:SearchQuery) => void

	// UseEffect on the fetchParams, to trigger the new fetch, when loadMore is called, or sortField/sortDirection have changed
	fetchParams:FetchParams
}

export const usePaginationV2:UsePaginationV2 = (props:UsePaginationV2Props) => {

	const [currentToken, setCurrentToken] = useState<string>(props.defaultStartToken);

	const [state, setState] = useState<FetchParams>({
		nextPageToken:props.defaultStartToken,
		sortDirection:props.defaultSortDirection,
		sortField:props.defaultSortField,
		loadMore:false,
		searchQuery:undefined
	});

	// reset token to start when sort is changing
	const onSortChange = (field:string, direction:string) => {
		console.log('UsePaginationV2: onSortChange', field, direction);
		setState({
			sortField:field,
			sortDirection:direction,
			nextPageToken:props.defaultStartToken,
			loadMore:false,
			searchQuery:state.searchQuery
		});
	};

	const newToken = (token:string) => {
		setCurrentToken(token);
	};

	const loadMore = () => {
		setState({...state, nextPageToken:currentToken, loadMore:true});
	};

	const onSearchChange = (searchQuery:SearchQuery) => {
		console.log('UsePaginationV2: onSearchChange', searchQuery);

		setState({
			...state,
			nextPageToken:props.defaultStartToken,
			searchQuery:searchQuery,
			loadMore:false
		});
	};

	return {
		loadMore,
		currentToken,
		setCurrentToken: newToken,
		onSortChange,
		onSearchChange,
		fetchParams: state
	};
};