import { Outlet, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { APP_ROUTES } from '../../../../constants/appRoutes';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export default function WithVariantsOverviewHeader() {
	const {productName, productId} = useParams<string>();

	const navigate = useNavigate();

	return (
		<>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl self-center cursor-pointer hover:text-gray-500"
					onClick={() => navigate('/sell/products')}
				>
                    Produkte
				</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>

				<h1 className="text-2xl text-gray-500 self-center">
					{productName}
				</h1>
			</div>
			<div>
				<Outlet />
			</div>
		</>
	);
}
