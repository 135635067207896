import {useState} from 'react';


type UsePagination = () => {
    nextPageToken:string
    loadMore: () => void
    setCurrentToken: (token:string)=> void
	currentToken:string
}

export const usePagination:UsePagination = () => {

	const [currentToken, setCurrentToken] = useState<string>('@start');
	const [nextPageToken, setNextPageToken] = useState<string>('');

	const newToken = (token:string) => {
		setCurrentToken(token);
	};

	const loadMore = () => {
		console.log('usePagination: current ', currentToken);
		console.log('usePagination: next ', nextPageToken);
		setNextPageToken(currentToken);
	};

	return {
		loadMore,
		nextPageToken,
		setCurrentToken: newToken,
		currentToken
	};
};