import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button, {ButtonType} from '../../../components/button/Button';
import DataTable, {TableColumn} from 'react-data-table-component';
import uuid from 'react-uuid';
import ExpandableVariants from './components/ExpandableVariants';
import {useNavigate} from 'react-router-dom';
import {APP_ROUTES, endPointGetImage, ENDPOINTS, routeDetailedProduct, routeVariantsOverview} from '../../../constants';
import {Product} from '../../../interfaces';


const customStyles = {
	expanderButton: {
		style: {
			minHeight: '72px', // override the row height
			'&:focus': {
				outline: 'none',
				backgroundColor: '#F5F5F5',
			},
		},
	},
};

export default function ProductTable() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [products, setProducts] = useState<Product[]>([]);


	const loadProducts = async (token: string) => {
		setLoading(true);
		axios.get(ENDPOINTS.listCompanyProducts, {
			params: {pageSize: 15, token: token ? token !== '' : undefined}
		})
			.then((response) => {
				setProducts(products.concat(response.data['products']));
				setNextPageToken(response.data['nextPageToken']);
				setTotalAmount(response.data['totalAmount']);
			})
			.catch((errors) => {
				console.log(errors);
			});
		setLoading(false);
	};

	useEffect(() => {
		void loadProducts('');
	}, []);


	function handleRowClicked(product:Product) {
		if (product.variants) {
			if (product.variants.length > 1) {
				navigate(routeVariantsOverview(product.id, product.name));

			} else if (product.variants.length === 1){
				navigate(routeDetailedProduct(product.id, product.variants[0].id, product.variants[0].name));
			}
		}
	}

	const colums: TableColumn<Product>[] = [
		{
			name: 'Produktbild',
			cell: (row, rowIndex, column) => (
				<div>
					{row.imageIds && (row.imageIds.length > 0) ?
						<img
							src={endPointGetImage(row.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={''}
							id={'img' + uuid()}
						/>
						:<></>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name:'Name',
			selector: row => row.name,
			center:true,
			width: '180px'
		},
		{
			name: 'Hersteller',
			selector: row => row.manufacturer?.name ?? '',
			center:true,
			width: '180px'
		}
	];

	return (
		<div className="px-4 sm:px-6 lg:px-8 mt-8">

			<div className={'flex justify-end'}>
				<Button
					wrapperClasses="pb-1"
					onClick={() => navigate(APP_ROUTES.selectCategory)}
					label="Produkt hinzufügen"/>
			</div>


			{products ? (
				<div className="flex flex-col">
					<DataTable
						columns={colums}
						data={products}
						customStyles={customStyles}
						expandableRows
						expandableRowsComponent={ExpandableVariants}
						paginationTotalRows={totalAmount}
						progressPending={loading}
						onRowClicked={(row)=>handleRowClicked(row)}
						pointerOnHover={true}/>

					{!(nextPageToken === '@end' || nextPageToken === '') &&
						<Button
							wrapperClasses="py-5 self-center"
							label="load more"
							onClick={() => loadProducts(nextPageToken)}/>
					}
				</div>
			) : (
				<div>Keine Produkte vorhanden...</div>
			)}
		</div>
	);
}
