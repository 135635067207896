import DataTable, {TableColumn} from 'react-data-table-component';
import {Manufacturer} from '../../../interfaces';
import React, {useEffect, useState} from 'react';
import {useDeleteManufacturerMutation, useLazyListManufacturersQuery,} from '../../../api/reduxAPI';
import {endPointGetImage} from '../../../constants';
import Button from '../../../components/button/Button';
import uuid from 'react-uuid';
import {AddManufacturerModal} from './components/AddManufacturerModal';
import IconButton, {IconType} from '../../../components/button/IconButton';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';
import {toast} from 'sonner';
import {EditManufacturerModal} from './components/UpdateManufacturerModal';
import {FormatDateWithTime} from '../../../utils/date';
import {PaginationDirection, usePaginationV2} from '../../../providers/usePaginationV2';
import {MultiSearchBar, MultiSearchBarItem} from '../../../components/multiSearchBar/MultiSearchBar';

const SearchFields: MultiSearchBarItem[] = [
	{
		label: 'Name',
		key: 'searchName'
	},
	{
		label: 'Status',
		key: 'searchStatus'
	}
];


export const Manufacturers = () => {

	const errorHandling = useErrorHandling();
	const [isCreateManufacturerModalOpen, setIsCreateManufacturerModalOpen] = useState<boolean>(false);
	const [deleteManfacturerSelected, setDeleteManfacturerSelected] = useState<Manufacturer>();
	const [editManufacturerSelected, setEditManufacturerSelected] = useState<Manufacturer>();

	const [deleteManufacturer] = useDeleteManufacturerMutation();

	const pagination = usePaginationV2({
		defaultSortField:'name',
		defaultSortDirection:PaginationDirection.ASC,
		defaultStartToken:''
	});
	const [allManufacturers, setAllManufacturers] = useState<Manufacturer[]>([]);
	const [listManufacturers] = useLazyListManufacturersQuery();


	useEffect(() => {
		try {
			const query:{[key:string]:string} = {};
			query[pagination.fetchParams.searchQuery?.key??'default'] = pagination.fetchParams.searchQuery?.value ?? '';

			listManufacturers({
				createdOwn:true,
				...query,
				pageSize:20,
				token: pagination.fetchParams.nextPageToken,
				sortPrimary: pagination.fetchParams.sortField,
				sortDirectionPrimary: pagination.fetchParams.sortDirection
			}).unwrap().then((res) => {

				pagination.setCurrentToken(res.nextPageToken);

				if (pagination.fetchParams.loadMore){
					setAllManufacturers(allManufacturers.concat(res.manufacturers));
				} else {
					setAllManufacturers(res.manufacturers);
				}
			});
		} catch (e:any){
			errorHandling(e);
		}
	}, [pagination.fetchParams]);

	const onEditClick = (manufacturer:Manufacturer) => {
		setEditManufacturerSelected(manufacturer);
	};

	const onDeleteClick = (manufacturer:Manufacturer) => {
		setDeleteManfacturerSelected(manufacturer);
	};

	const onConfirmDelete =  async () => {
		try {
			await deleteManufacturer({manufacturerID:deleteManfacturerSelected?.id ? deleteManfacturerSelected.id : ''}).unwrap();
			setDeleteManfacturerSelected(undefined);
			toast.success('Hersteller erfolgreich gelöscht');
		} catch (err:any) {
			errorHandling(err);
		}
	};

	const colums: TableColumn<Manufacturer>[] = [
		{
			name: 'Logo',
			cell: (row, rowIndex, column) => (
				<div>
					{row.imageId ?
						<img
							src={endPointGetImage(row.imageId)}
							className="w-16 h-16 object-scale-down"
							alt={''}
							id={'img' + uuid()}
						/>
						:<></>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name: 'Name',
			id:'name',
			sortable:true,
			cell: (row) =>
				<div>
					{row.name}
				</div>,
			width: '180px'
		},
		{
			name: 'Status',
			id:'manufacturerState',
			sortable:true,
			cell: (row) =>
				<div>
					{row.manufacturerState}
				</div>,
			width: '180px'
		},
		{
			name: 'Erstellt',
			id:'created',
			sortable:true,
			cell: (row) =>
				<div>
					{FormatDateWithTime(row.created)}
				</div>,
			width: '180px'
		},
		{
			name: 'Updated',
			id:'updated',
			sortable:true,
			cell: (row) =>
				<div>
					{FormatDateWithTime(row.updated)}
				</div>,
			grow:1
		},
		{
			name: 'Bearbeiten',
			cell: (row) =>
				<div className="justify-self-end flex gap-3">
					<IconButton
						onClick={() => onEditClick(row)}
						icon={IconType.PENCIL}
					/>
					<IconButton
						onClick={() => onDeleteClick(row)}
						icon={IconType.TRASH}
					/>
				</div>,
			right:true,
		},
	];


	return (
		<>
			<div className={'flex justify-end'}>
				<Button
					wrapperClasses="pb-1"
					onClick={() => setIsCreateManufacturerModalOpen(true)}
					label="Marke Registrieren"
				/>
				<AddManufacturerModal
					isOpen={isCreateManufacturerModalOpen}
					onClose={()=>setIsCreateManufacturerModalOpen(false)}
				/>
			</div>

			<MultiSearchBar
				searchFields={SearchFields}
				onChange={pagination.onSearchChange}
			/>

			<DataTable
				columns={colums}
				data={allManufacturers}
				defaultSortFieldId={1}
				defaultSortAsc={false}
				sortServer
				onSort={(column, sortDirection)=>{
					pagination.onSortChange(
						typeof column.id === 'string' ? column.id : 'name',
						sortDirection === 'desc' ? PaginationDirection.DESC : PaginationDirection.ASC // translate DataTable sort direction to PaginationV2Direction
					);
				}}
			/>

			{editManufacturerSelected &&
				<EditManufacturerModal
					isOpen={editManufacturerSelected !== undefined}
					onClose={()=>setEditManufacturerSelected(undefined)}
					manufacturer={editManufacturerSelected}
				/>
			}

			<ConfirmationModal
				isVisible={deleteManfacturerSelected !== undefined}
				text={'Sind sie sich sicher, dass sie den Hersteller ' + deleteManfacturerSelected?.name + ' löschen wollen?' }
				onClose={()=>setDeleteManfacturerSelected(undefined)}
				onSuccess={()=>onConfirmDelete().then()}
			/>

			{!(pagination.currentToken === '@end' || pagination.currentToken === '') &&
				<div className={'flex justify-center'}>
					<Button
						wrapperClasses="py-5 self-center"
						label="Mehr laden"
						onClick={() => pagination.loadMore()}
					/>
				</div>
			}
		</>
	);
};