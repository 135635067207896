import DataTable, {TableColumn} from 'react-data-table-component';
import {Company} from '../../interfaces';
import React, {useEffect, useState} from 'react';
import {Constants} from '../../constants';
import Button from '../../components/button/Button';
import IconButton, {IconType} from '../../components/button/IconButton';
import SearchBar from '../../components/searchBar/SearchBar';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import {toast} from 'sonner';
import {
	useListSellerCompaniesQuery,
	useRequestCompanyPartnershipMutation
} from '../../api/reduxAPI';
import {usePagination} from '../../providers/usePagination';

export default function PartnershipSelection() {
	
	const errorHandling = useErrorHandling();
	const pagination = usePagination();

	const [requestPartnership] = useRequestCompanyPartnershipMutation();

	const [sortDirection, setSortDirection] = useState<string>(Constants.SortDirection.ASC);
	const [sortField, setSortField] = useState<string>('name');
	const [searchAfterName, setSearchAfterName] = useState<string>('');

	const {data:{companies = [], nextPageToken = ''} = {} }  = useListSellerCompaniesQuery({
		isSellingToUs: false,
		searchName: searchAfterName !== '' ? searchAfterName : undefined,
		pageSize:20,
		token: pagination.nextPageToken,
		sortPrimary:sortField,
		sortDirectionPrimary:sortDirection === 'desc' ? 'DESC' : 'ASC'
	});

	useEffect(() => {
		pagination.setCurrentToken(nextPageToken);
	}, [nextPageToken]);

	const onSortChange = (field:string, direction:string) => {
		setSortField(field);
		setSortDirection(direction);
	};


	const onRequestCompanyPartnership = async (sellerID:string, sellerName:string) => {
		try {
			await requestPartnership({partnerCompanyID:sellerID}).unwrap();

			const message = 'Anfrage zur Partnerschaft mit dem Unternehmen ' + sellerName + ' gestellt.';
			toast.success(message);
		} catch (e:any){
			errorHandling(e);
		}
	};


	const colums: TableColumn<Company>[] = [
		{
			name: 'Name',
			selector: row => row.name,
			center: true,
			sortable: true,
			width: '250px'
		},
		{
			name: 'Anfrage',
			right: true,
			grow: 1,
			cell: (row, rowIndex, column) =>
				<div className="flex items-center gap-2 pr-1">
					<IconButton
						onClick={() => onRequestCompanyPartnership(row.id, row.name).then()}
						icon={IconType.PLUS}
						title='Partnerschaft anfragen'/>
				</div>
		},
	];

	return (
		<div>
			<SearchBar
				wrapperClasses={'w-1/3 pt-12 px-12'}
				results={companies}
				searchStr={searchAfterName}
				setSearchStr={setSearchAfterName}
				onClickResult={(id: string, name: string) => onRequestCompanyPartnership(id, name).then()}/>

			<div className={'w-1/3 px-12 flex flex-col'}>
				<DataTable
					columns={colums}
					data={companies}
					defaultSortFieldId={1}
					defaultSortAsc={true}
					sortServer
					onSort={(column, sortDirection)=>onSortChange(column.sortField??'', sortDirection)}
				/>

				{!(nextPageToken === '@end' || nextPageToken === '') &&
					<Button
						wrapperClasses="py-5 self-center"
						label="Mehr laden"
						onClick={() => pagination.loadMore()}
					/>
				}
			</div>
		</div>
	);
}
