import React, {useEffect, useRef} from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactPortal from './ReactPortal';
import {XMarkIcon} from '@heroicons/react/20/solid';

export interface ModalV2Props {
    children: any,
    isOpen: boolean,
    handleClose: any
}

export const ModalV2 = ({  children, isOpen, handleClose }: ModalV2Props) => {

	const nodeRef = useRef(null);

	useEffect(() => {
		const closeOnEscapeKey = (e: any) => {
			if (!isOpen) return;

			if (e.key === 'Escape') {
				handleClose();
			}
		};

		document.body.addEventListener('keydown', closeOnEscapeKey);
		return () => {
			document.body.removeEventListener('keydown', closeOnEscapeKey);
		};

	}, [handleClose, isOpen]);

	return (
		<ReactPortal wrapperId="modal">
			<CSSTransition
				in={isOpen}
				timeout={{ enter: 0, exit: 300 }}
				unmountOnExit
				classNames="modal"
				nodeRef={nodeRef}
			>
				<div className="absolute inset-0 z-50" ref={nodeRef}>
					{isOpen &&
						<div className="relative z-10">
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
							<div className="fixed z-10 inset-0 overflow-y-auto">
								<div className="h-full flex items-end sm:items-center justify-center p-5 text-center sm:p-0">
									<div className="flex flex-col relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-6">

										<div className={'relative flex justify-end'}>
											<XMarkIcon
												className="h-9 w-9  hover:text-gray-500 cursor-pointer"
												onClick={() => handleClose()}
											/>
										</div>

										<div className={'p-4'}>
											{children}
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			</CSSTransition>
		</ReactPortal>
	);
};
